import React, { useContext, useState, useEffect, Fragment } from "react";
import { auth, database } from "../Other/firebase";
import {
  signInWithPopup,
  GoogleAuthProvider,
  getRedirectResult,
} from "firebase/auth";
import { BackendServerURL } from "../Other/constants";
import lottieJson from "../../images/loadingBlack.json";
import Lottie from "react-lottie-player";
import useGeoLocation from "react-ipgeolocation";
import toastSuccess from "../../images/toastSuccess.svg";
import toastError from "../../images/toastError.svg";
import toastWarning from "../../images/toastWarning.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Cookies from "js-cookie";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName="background-clr-alt"
      style={{ backgroundColor: "" }}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.modalBody}</Modal.Body>
      <Modal.Footer>
        {props.modalNegativeBtn.length > 0 ? (
          <Button variant="secondary" onClick={props.onHide}>
            {props.modalNegativeBtn}
          </Button>
        ) : (
          ""
        )}
        {props.modalPositiveBtn.length > 0 ? (
          <Button
            onClick={(e) => {
              e.target.classList.add("disable-button");
              props.onPositiveClick();
            }}
          >
            {props.modalPositiveBtn}
          </Button>
        ) : (
          ""
        )}
      </Modal.Footer>
    </Modal>
  );
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserUID, setCurrentUserUID] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodsFetched, setPaymentMethodsFetched] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingAnim, setLoadingAnim] = useState(true);
  /**
   * 0 -> not fetched data yet
   * 1 -> subscribed and active
   * -1 -> subscribed but not active
   * 2 -> not subscribed
   */
  const [subscribed, setSubscribed] = useState(0);
  const [userSubscription, setUserSubscription] = useState(null);
  const [stripeCustomer, setStripeCustomer] = useState(null);
  const [stripeId, setStripeId] = useState(null);
  const [earlyAccessPage, setEarlyAccessPage] = useState(false);
  const [textOnly, setTextOnly] = useState(false);
  const [textType, setTextType] = useState(false);
  const { country, _, __ } = useGeoLocation();
  const [authToken, setAuthToken] = useState("private_jvCxusNZlu1rq8rz");
  const localAuthTokenSave = "localAuthTokenSave";
  const localGithubAccessToken = "localGithubAccessToken";
  const apiCallUrl = "http://localhost:5000/api";
  const toastMessageTime = 1600;
  const [toastMessage, setToastMessage] = useState("");
  const [toastVisible, setToastVisible] = useState(false);
  const toastDetails = {
    success: { color: "#EDFFEA", icon: toastSuccess },
    error: { color: "#FFE1E1", icon: toastError },
    warning: { color: "#FFF5DC", icon: toastWarning },
  };
  const [modalShow, setModalShow] = React.useState(false);
  const [modalTitle, setModalTitle] = useState("title");
  const [modalBody, setModalBody] = useState(<Fragment></Fragment>);
  const [modalNegativeBtn, setModalNegativeBtn] = useState("close");
  const [modalPositiveBtn, setModalPositiveBtn] = useState("done");
  const [modalPositiveBtnClick, setModalPositiveBtnClick] = useState(null);

  useEffect(() => {
    console.log(modalPositiveBtnClick);
  }, [modalPositiveBtnClick]);

  const addToastMessage = (message, type) => {
    let icon = toastDetails.success.icon;
    switch (type) {
      case "success":
        icon = toastDetails.success.icon;
        break;
      case "error":
        icon = toastDetails.error.icon;
        break;
      case "warning":
        icon = toastDetails.warning.icon;
        break;
    }
    setToastMessage(message);
    document.getElementsByClassName("toast-message")[0].style.background =
      toastDetails.success.color;
    document.getElementsByClassName("toast-message-img")[0].src = icon;
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, toastMessageTime);
  };
  function landingPageSwitch(url, textType = false) {
    if (textType) {
      setTextType(url);
      setTextOnly(true);
    } else {
      if (textOnly) {
        setTextOnly(false);
        setTimeout(() => (window.location.href = url), 250);
      } else {
        window.location.href = url;
      }
    }
  }

  async function addToWaitlist(fullName, email) {
    const waitlistRef = database.ref("waitlist");
    waitlistRef
      .push({
        email: email,
        fullName: fullName,
        timestamp: Date.now(),
        location: country !== undefined ? country : "N/A",
      })
      .then((val) => console.log(val));
  }

  async function signup(email, password, fullName) {
    await auth
      .createUserWithEmailAndPassword(email, password)
      .then(function (result) {
        if (result.user) {
          result.user.updateProfile({
            displayName: fullName,
          });
          console.log(result.user.uid);
          let data = result.user;
          let ref = database.ref("users").child(data.uid);
          ref
            .set({ id: data.uid, email: data.email, fullName: fullName })
            .then(function () {
              console.log("User created successfully!");
            })
            .catch(function (error) {
              console.log("Error creating user:", error);
            });
          setCurrentUserUID(data.uid);
          // setLoading(false)
        }
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        switch (errorCode) {
          case "auth/invalid-password":
            console.log("Invalid password");
            document.getElementById("password-error").innerHTML =
              "Invalid password";
            document.getElementById("password-error").style.display = "block";
            document.getElementById("password").style.borderColor = "red";
            break;
          case "auth/invalid-display-name":
            console.log("Invalid display name");
            document.getElementById("fname-error").innerHTML =
              "This field cannot contain special characters";
            document.getElementById("fname-error").style.display = "block";
            document.getElementById("fname").style.borderColor = "red";
            break;
          case "auth/email-already-exists":
            console.log("Entered email address is already registered");
            document.getElementById("email-error").innerHTML =
              "Entered email address is already registered";
            document.getElementById("email-error").style.display = "block";
            document.getElementById("email").style.borderColor = "red";
            break;
          case "auth/invalid-email":
            console.log("Invalid email.");
            document.getElementById("email-error").innerHTML =
              "Enter a valid email";
            document.getElementById("email-error").style.display = "block";
            document.getElementById("email").style.borderColor = "red";

          default:
            console.log(error);
        }
        // ...
      });
  }

  async function login(email, password) {
    await auth
      .signInWithEmailAndPassword(email, password)
      .then(function (result) {
        if (result.user) {
          console.log(result.user.uid);
          let data = result.user;
          setCurrentUserUID(data.uid);
          // setLoading(false)
        }
      })
      .catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        switch (errorCode) {
          case "auth/wrong-password":
            console.log("Wrong password.");
            document.getElementById("password-error").innerHTML =
              "Enter the correct password";
            document.getElementById("password-error").style.display = "block";
            document.getElementById("password").style.borderColor = "red";
            break;
          case "auth/user-not-found":
            console.log("User not found.");
            document.getElementById("email-error").innerHTML =
              "Entered email is not registered";
            document.getElementById("email-error").style.display = "block";
            document.getElementById("email").style.borderColor = "red";
            break;
          case "auth/invalid-email":
            console.log("Invalid email.");
            document.getElementById("email-error").innerHTML =
              "Enter a valid email";
            document.getElementById("email-error").style.display = "block";
            document.getElementById("email").style.borderColor = "red";

          default:
            console.log(error);
        }
        // ...
      });
  }

  async function loginWithGoogle() {
    const provider = new GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    await signInWithPopup(auth, provider);
    // This will trigger a full page redirect away from your app

    // After returning from the redirect when your app initializes you can obtain the result
    const result = await getRedirectResult(auth);
    if (result) {
      // This is the signed-in user
      const user = result.user;
      // This gives you a Google Access Token.
      const credential = provider.credentialFromResult(auth, result);
      const token = credential.accessToken;
      setCurrentUserUID(user.uid);
      // setLoading(false)
    }
  }

  async function logout() {
    await auth.signOut().catch(function (error) {
      // An error happened.
      console.log(error);
    });
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  async function checkSubscriptionFirebase() {
    if (currentUserData !== null) {
      if ("stripeId" in currentUserData) {
        setStripeId(currentUserData["stripeId"]);
      } else {
        console.log("Creating customer");
        await fetch(BackendServerURL + "/create-customer", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: currentUser.email,
          }),
        })
          .then((r) => r.json())
          .then(async (data) => {
            if (data.customer.id) {
              setStripeId(data.customer.id);
            }
            await database
              .ref("users")
              .child(currentUser.uid)
              .child("stripeId")
              .set(data.customer.id);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      await checkSubscriptionStatus();
    } else {
      database
        .ref("users")
        .child(currentUser.uid)
        .child("id")
        .set(currentUser.uid);
    }
  }

  async function checkSubscriptionStatus() {
    if ("subscriptionId" in currentUserData) {
      await fetch(BackendServerURL + "/get-subscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscriptionId: currentUserData.subscriptionId,
        }),
      })
        .then((r) => r.json())
        .then(({ subscription }) => {
          console.log("subscription", subscription);
          if (subscription) {
            setUserSubscription(subscription);
            if (subscription.status === "active") {
              setSubscribed(1);
            } else {
              setSubscribed(-1);
            }
          }
        })
        .catch((error) => {
          setSubscribed(2);
          console.log(error);
        });
    } else {
      setSubscribed(2);
      console.log("Creating customer");
    }
  }

  async function getUsersStripeDetails() {
    let tempStripeId = null;
    if (stripeId !== null) {
      tempStripeId = stripeId;
    } else if (currentUserData !== null && "stripeId" in currentUserData) {
      tempStripeId = currentUserData.stripeId;
      setStripeId(currentUserData.stripeId);
    }

    await fetch(BackendServerURL + "/get-customer?id=" + tempStripeId)
      .then((r) => r.json())
      .then(({ customer }) => {
        setStripeCustomer(customer);
        console.log("Customer :: ", customer);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getBookmarkList(listName) {
    if (currentUserData === null) {
      console.log("No user data");
      return;
    }
    let ret = [];
    if (
      "userBookmarks" in currentUserData &&
      listName in currentUserData.userBookmarks
    ) {
      Object.keys(currentUserData.userBookmarks[listName]).forEach(function (
        key
      ) {
        if (currentUserData.userBookmarks[listName][key] === true) {
          ret.push(key);
        }
      });
    }
    return ret;
  }

  async function createBookMarkList(newList) {
    if (currentUserData === null) {
      console.log("No user data");
      return;
    }
    let currentLists = currentUserData.bookmarkLists;
    if (currentLists === null) {
      currentLists = [];
    }
    currentLists.push(newList);
    database
      .ref("users")
      .child(currentUser.uid)
      .child("bookmarkLists")
      .set(currentLists);
  }

  async function addToBookMarksList(listName, itemtoAdd) {
    if (currentUserData === null) {
      console.log("No user data");
      return;
    }
    let userBookmarks = {};
    if ("userBookmarks" in currentUserData) {
      userBookmarks = currentUserData.userBookmarks;
    }
    if (!(listName in userBookmarks)) {
      userBookmarks[listName] = {};
    }
    userBookmarks[listName][itemtoAdd] = true;
    database
      .ref("users")
      .child(currentUser.uid)
      .child("userBookmarks")
      .set(userBookmarks);
  }

  async function removeFromBookMarksList(listName, itemtoAdd) {
    if (currentUserData === null) {
      console.log("No user data");
      return;
    }
    let userBookmarks = {};
    if ("userBookmarks" in currentUserData) {
      userBookmarks = currentUserData.userBookmarks;
    }
    if (!(listName in userBookmarks)) {
      userBookmarks[listName] = {};
    }
    userBookmarks[listName][itemtoAdd] = false;
    database
      .ref("users")
      .child(currentUser.uid)
      .child("userBookmarks")
      .set(userBookmarks);
  }

  async function setSubscriptionId(subscriptionId) {
    if (currentUserData === null) {
      console.log("No user data");
      return;
    }
    database
      .ref("users")
      .child(currentUser.uid)
      .child("subscriptionId")
      .set(subscriptionId);
  }

  async function getPaymentMethods() {
    if (stripeId === null) {
      console.log("No stripe id");
      return;
    }
    await fetch(BackendServerURL + "/get-customer-cards?id=" + stripeId)
      .then((r) => r.json())
      .then(({ paymentMethod }) => {
        if ("data" in paymentMethod) {
          if (stripeCustomer !== null) {
            let otherPayments = paymentMethod.data.filter(
              (item) => item.id !== stripeCustomer.default_source
            );
            setPaymentMethods(otherPayments);

            setActiveCard(
              paymentMethod.data.filter(
                (item) => item.id === stripeCustomer.default_source
              )[0]
            );
          }
          setPaymentMethodsFetched(true);
        }
      })
      .catch((e) => {
        console.log(e);
        setPaymentMethodsFetched(true);
      });
  }

  async function addCard(tokenId, setActive) {
    if (stripeId === null) {
      console.log("No stripe id");
      return;
    }
    await fetch(BackendServerURL + "/add-card", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customerId: stripeId,
        tokenId: tokenId,
      }),
    })
      .then((r) => r.json())
      .then(async (createdCard) => {
        if (setActive) {
          await setCardActive(createdCard.paymentMethod.id);
        }
        await getPaymentMethods();
      })
      .catch((error) => {});
  }

  async function updateCard(cardId, updateObject, setActive) {
    if (stripeId === null) {
      console.log("No stripe id");
      return;
    }
    await fetch(BackendServerURL + "/update-card", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customerId: stripeId,
        cardId: cardId,
        updateObject: updateObject,
      }),
    })
      .then((r) => r.json())
      .then(async (createdCard) => {
        if (setActive) {
          await setCardActive(createdCard.paymentMethod.id);
        }
        await getPaymentMethods();
      })
      .catch((error) => {});
  }

  async function setCardActive(cardId) {
    await fetch(BackendServerURL + "/update-default-card", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customerId: stripeId,
        cardId: cardId,
      }),
    })
      .then((r) => r.json())
      .then(async (data) => {
        await getUsersStripeDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function deleteCard(cardId) {
    await fetch(BackendServerURL + "/delete-card", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customerId: stripeId,
        cardId: cardId,
      }),
    })
      .then((r) => r.json())
      .then(async (data) => {
        await getUsersStripeDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const getGithubAccessToken = async (githubCode) => {
    await fetch("http://localhost:5000/api/users/login/github", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        client_id: process.env.REACT_APP_GH_BASIC_CLIENT_ID,
        client_secret: process.env.REACT_APP_GH_BASIC_SECRET_ID,
        code: githubCode,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if ("access_token" in data) {
          Cookies.set(localGithubAccessToken, data.access_token, {
            expires: 60,
          });
          getGithubUser();
        }
      })
      .catch((e) => console.log(e));
  };
  const isGithubAuthTokenAvailable = () => {
    const token = Cookies.get(localGithubAccessToken);
    return token !== null && token !== "";
  };
  const getGithubUser = async () => {
    if (isGithubAuthTokenAvailable()) {
      await fetch("https://api.github.com/user", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + Cookies.get(localGithubAccessToken),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((r) => r.json())
        .then((data) => {
          console.log(data);
        })
        .catch((e) => console.log(e));
    }
  };
  async function cancelSubscription() {
    await fetch(BackendServerURL + "/cancel-subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subscriptionId: currentUserData.subscriptionId,
      }),
    })
      .then((r) => r.json())
      .then(async ({ deletedSubscription, error }) => {
        if (deletedSubscription) {
          console.log("deletedSubscription", deletedSubscription);
          await getUsersStripeDetails();
          await checkSubscriptionStatus();
        } else {
          console.log(error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function updateSubscription(subscriptionItemId, priceId) {
    if (!priceId || !subscriptionItemId) {
      return;
    }
    await fetch(BackendServerURL + "/update-subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subscriptionItemId: subscriptionItemId,
        priceId: priceId,
      }),
    })
      .then((r) => r.json())
      .then(async ({ updatedSubscription, error }) => {
        if (updatedSubscription) {
          console.log("updatedSubscription", updatedSubscription);
          await getUsersStripeDetails();
          await checkSubscriptionStatus();
        } else {
          console.log(error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(async () => {
    if (currentUserData) {
      await checkSubscriptionFirebase();
      await getUsersStripeDetails();
      setLoading(false);
    }
  }, [currentUserData]);

  useEffect(async () => {
    if (currentUser) {
      console.log("Current User Id: ", currentUser.uid);
      const onValueChange = database
        .ref("users")
        .child(currentUser.uid)
        .on("value", (snapshot) => {
          console.log("User data: ", snapshot.val());
          setCurrentUserData(snapshot.val());
        });
      // Stop listening for updates when no longer required
      return () =>
        database
          .ref("users")
          .child(currentUser.uid)
          .off("value", onValueChange);
    }
  }, [currentUser]);

  useEffect(async () => {
    if (stripeCustomer && stripeId) {
      await getPaymentMethods();
    }
  }, [stripeCustomer, stripeId]);

  useEffect(() => {
    // setAuthToken(Cookies.get(localAuthTokenSave));
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
      if (user && "uid" in user) {
        setCurrentUserUID(user["uid"]);
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    loginWithGoogle,
    resetPassword,
    updateEmail,
    updatePassword,
    subscribed,
    stripeId,
    currentUserData,
    userSubscription,
    createBookMarkList,
    addToBookMarksList,
    removeFromBookMarksList,
    getBookmarkList,
    stripeCustomer,
    addToWaitlist,
    setSubscriptionId,
    getPaymentMethods,
    paymentMethods,
    activeCard,
    paymentMethodsFetched,
    setCardActive,
    deleteCard,
    addCard,
    updateCard,
    cancelSubscription,
    updateSubscription,
    earlyAccessPage,
    setEarlyAccessPage,
    textOnly,
    setTextOnly,
    textType,
    setTextType,
    landingPageSwitch,
    authToken,
    apiCallUrl,
    addToastMessage,
    setModalTitle,
    setModalBody,
    setModalNegativeBtn,
    setModalPositiveBtn,
    setModalPositiveBtnClick,
    setModalShow,
    getGithubAccessToken,
    getGithubUser,
    localAuthTokenSave,
  };

  return (
    <AuthContext.Provider value={value}>
      <div
        className={
          toastVisible
            ? "toast-message-wrapper toast-come-up"
            : "toast-message-wrapper"
        }
      >
        <div className="toast-message">
          <img className="toast-message-img" src="" />
          <div className="toast-message-text">
            <span>{toastMessage}</span>
          </div>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onPositiveClick={modalPositiveBtnClick}
        modalBody={modalBody}
        modalTitle={modalTitle}
        modalPositiveBtn={modalPositiveBtn}
        modalNegativeBtn={modalNegativeBtn}
      />
      {loading || loadingAnim ? (
        <div className="loading-wrapper">
          <Lottie
            loop
            animationData={lottieJson}
            play
            onLoopComplete={() => {
              if (!loading) {
                setLoadingAnim(false);
              }
            }}
            style={{ width: 150, height: 150 }}
          />
          <span
            style={{
              fontSize: "14px",
            }}
          >
            Pac-Man is hungry, give him a sec
          </span>
        </div>
      ) : (
        children
      )}
      {/* {!loading && children} */}
    </AuthContext.Provider>
  );
}

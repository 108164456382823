import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import NavigationBar from "../Custom/NavigationBar";
import { Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import { useWindowDimensions } from "../Contexts/WindowDimension";
import peopleLessImg from "../../images/peopleLess.png";
import peopleTeamImg from "../../images/peopleTeam.png";
import moreArrow from "../../images/more_arrow.svg";
import GetOnWaitlistElem from "./GetOnWaitlistElem";

const WhoIsThisFor = (props) => {
  const [priceLookupKey, setPriceLookupKey] = useState(null);
  const [monthly, setMonthly] = useState(true);
  let history = useHistory();
  const { width, height } = useWindowDimensions();
  const isDesktop = width > 1024;
  const [waitlistMode, setWaitlistMode] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const { paymentMethodsFetched, paymentMethods, activeCard, addToWaitlist } =
    useAuth();
  const [currentTileIdx, setCurrentTileIdx] = useState(2);
  const onEmailEnterClick = () => {
    addToWaitlist(name, email);
  };
  const getPriceTile = (monthly, idx) => {
    switch (idx) {
      case 0:
        return (
          <div className="price-tile">
            <div
              className="vertical-container"
              style={{ height: "98%", paddingBottom: "10px" }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "32px",
                }}
              >
                Small-sized team
              </div>
              <div
                className="center"
                style={{
                  height: "73%",
                }}
              >
                <img
                  style={{
                    width: "151px",
                  }}
                  src={peopleLessImg}
                />
              </div>
              <div
                style={{
                  marginTop: "auto",
                  marginBottom: "24px",
                }}
              >
                If you’re a team of 2-6 people running a startup and finding
                product-market fit
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="price-tile">
            <div
              className="vertical-container"
              style={{ height: "98%", paddingBottom: "10px" }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "32px",
                }}
              >
                Medium-sized team
              </div>
              <div
                className="center"
                style={{
                  height: "73%",
                }}
              >
                <img
                  style={{
                    width: "247px",
                  }}
                  src={peopleTeamImg}
                />
              </div>
              <div
                style={{
                  marginTop: "auto",
                  width: "247px",
                }}
              >
                If you’re a team of more than 6 people and are actively
                developing your product by adding more features
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="price-tile">
            <div
              className="vertical-container"
              style={{ height: "98%", paddingBottom: "10px" }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "32px",
                }}
              >
                We want to help you!
              </div>
              <div>Click the appropriate button</div>
              <div
                style={{
                  background: "#505050",
                  color: "#505050",
                  borderColor: "#505050",
                  height: "1px",
                  marginTop: "45px",
                }}
              />
              <div>If you’re a solo founder</div>
              <button
                className="transparent-button transparent-button-hover"
                onClick={() => setCurrentTileIdx(3)}
              >
                <u>I’m a solo founder</u>
              </button>
              <div
                style={{
                  background: "#505050",
                  color: "#505050",
                  borderColor: "#505050",
                  height: "1px",
                }}
              />
              <div>If you’re an organisation of more than 50 people</div>
              <button
                className="transparent-button transparent-button-hover"
                onClick={() => setCurrentTileIdx(4)}
              >
                <u>We are an organisation</u>
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="price-tile">
            <div
              className="vertical-container"
              style={{ height: "98%", paddingBottom: "10px" }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "32px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={moreArrow}
                  style={{
                    transform: "rotate(180deg)",
                    marginRight: "16px",
                    cursor: "pointer",
                    padding: "8px",
                    paddingRight: "0",
                  }}
                  onClick={() => setCurrentTileIdx(2)}
                />
                Solo founder
              </div>
              <div>
                Send us your email, so we can contact you and help you the best
                way.
                <br></br>
                <br></br>
                We promise, no spams:)
              </div>
              <div
                style={{ width: "100%", height: "52px", position: "relative" }}
              >
                <input
                  id="emailInput"
                  type="email"
                  className="transparent-button"
                  style={{
                    border: "1px solid #f2f2f2",
                    width: "inherit",
                    padding: "0 8px",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div
                  className={
                    "floating-label emailInput floating-label-card floating-label-card" +
                    (email.length > 0
                      ? " emailInputFocus floating-label-focus"
                      : "")
                  }
                  style={{ background: "#232628" }}
                >
                  <span>Enter your email here</span>
                </div>
              </div>

              <button
                className="join-waitlist-button join-waitlist-button-hover"
                style={{ width: "100%", marginTop: "65px" }}
                onClick={() => onEmailEnterClick()}
              >
                Submit
              </button>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="price-tile">
            <div
              className="vertical-container"
              style={{ height: "98%", paddingBottom: "10px" }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "32px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={moreArrow}
                  style={{
                    transform: "rotate(180deg)",
                    marginRight: "16px",
                    cursor: "pointer",
                    padding: "8px",
                    paddingRight: "0",
                  }}
                  onClick={() => setCurrentTileIdx(2)}
                />
                Organisation
              </div>
              <div>
                Send us your email, so we can contact you and help you the best
                way.
                <br></br>
                <br></br>
                We promise, no spams:)
              </div>
              <div
                style={{ width: "100%", height: "52px", position: "relative" }}
              >
                <input
                  id="nameInput"
                  type="text"
                  className="transparent-button"
                  style={{
                    border: "1px solid #f2f2f2",
                    width: "inherit",
                    padding: "0 8px",
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <div
                  className={
                    "floating-label nameInput floating-label-card" +
                    (name.length > 0
                      ? " nameInputFocus floating-label-focus"
                      : "")
                  }
                  style={{ background: "#232628" }}
                >
                  <span>Name of the organisation</span>
                </div>
              </div>
              <div
                style={{ width: "100%", height: "52px", position: "relative" }}
              >
                <input
                  id="emailInput"
                  type="email"
                  className="transparent-button"
                  style={{
                    border: "1px solid #f2f2f2",
                    width: "inherit",
                    padding: "0 8px",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div
                  className={
                    "floating-label emailInput floating-label-card" +
                    (email.length > 0
                      ? " emailInputFocus floating-label-focus"
                      : "")
                  }
                  style={{ background: "#232628" }}
                >
                  <span>Enter your organisation email here</span>
                </div>
              </div>

              <button
                className="join-waitlist-button join-waitlist-button-hover"
                style={{ width: "100%" }}
                onClick={() => onEmailEnterClick()}
              >
                Submit
              </button>
            </div>
          </div>
        );
      default:
        return "";
    }
  };

  const getAnimatedtile = (idx) => {
    // hide other price-tiles
    if (isDesktop) {
      for (let i = 0; i < 3; i++) {
        if (i !== idx) {
          document
            .getElementsByClassName("price-tile")
            [i].classList.add("fadeOut");
        }
      }
      setTimeout(() => {
        for (let i = 0; i < 3; i++) {
          if (i !== idx) {
            document.getElementsByClassName("price-tile")[i].style.display =
              "none";
          }
        }
      }, 500);
    } else {
      setWaitlistMode(true);
      return;
    }
    document
      .getElementsByClassName("coming-soon-triangle")
      [idx - 1].classList.add("coming-soon-whiteBg");
    document
      .getElementsByClassName("coming-soon-title")
      [idx - 1].classList.add("coming-soon-title-whiteBg");
    document
      .getElementsByClassName("price-tile")
      [idx].classList.add("price-tile-custom");
    document.getElementsByClassName("waitlist-btn-wrapper")[idx - 1].innerHTML =
      "Select an option on the right";
    setTimeout(() => {
      setWaitlistMode(true);
    }, 700);
  };

  if (priceLookupKey) {
    if (!paymentMethodsFetched) {
      // Toast message error
      history.push("/");
    }
    if (paymentMethods.length === 0 && !activeCard) {
      history.push("/add-card", { toSubscribe: true, priceId: priceLookupKey });
    } else {
      history.push("/subscribe", { priceId: priceLookupKey });
    }
  }

  const changePage = (page) => {
    document
      .getElementById("useCasePagination")
      .childNodes.forEach((child, idx) => {
        if (page === idx) child.classList.add("selected-page");
        else child.classList.remove("selected-page");
      });
  };
  return (
    <div>
      <NavigationBar hideLogin hidePricing />
      <div className="pricing-main-wrapper">
        <div className="pricing-main-title">Who is this for?</div>
      </div>
      {isDesktop ? (
        <div className="price-list">
          {getPriceTile(monthly, 0)}
          {getPriceTile(monthly, 1)}
          {getPriceTile(monthly, currentTileIdx)}
          {waitlistMode ? getPriceTile(monthly, 3) : ""}
        </div>
      ) : (
        <Fragment>
          <Swiper
            modules={[Scrollbar]}
            scrollbar={{
              draggable: true,
              snapOnRelease: true,
              hide: true,
            }}
            spaceBetween={1}
            slidesPerView={1}
            centeredSlides={true}
            roundLengths={true}
            onSlideChange={(idx) => changePage(idx.realIndex)}
          >
            <Fragment>
              <SwiperSlide>{getPriceTile(monthly, 0)}</SwiperSlide>
              <SwiperSlide>{getPriceTile(monthly, 1)}</SwiperSlide>
              <SwiperSlide>{getPriceTile(monthly, currentTileIdx)}</SwiperSlide>
            </Fragment>
          </Swiper>
          <div
            id="useCasePagination"
            className="horizontal-container"
            style={{
              justifyContent: "center",
              marginTop: "8px",
            }}
          >
            <div className="page-normal selected-page"></div>
            <div className="page-normal"></div>
            <div className="page-normal"></div>
          </div>
        </Fragment>
      )}
      <GetOnWaitlistElem uid="usecase" />
    </div>
  );
};

export default withRouter(WhoIsThisFor);

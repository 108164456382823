import React, { useEffect, useState, Fragment } from "react";
import privacyPolicy from "../LandingPage/PrivacyPolicy.txt";
import termsOfUse from "../LandingPage/TermsOfUse.txt";
import disclaimer from "../LandingPage/Disclaimer.txt";
import { useAuth } from "../Contexts/AuthContext";

function TextOnly(props) {
  const { isMobile } = props;
  const { textType } = useAuth();
  const [formattedText, setFormattedText] = useState("");
  const getFile = () => {
    switch (textType) {
      case "privacyPolicy":
        return privacyPolicy;
      case "disclaimer":
        return disclaimer;
      case "termsOfUse":
        return termsOfUse;
    }
    return null;
  };
  const getTitle = () => {
    switch (textType) {
      case "privacyPolicy":
        return "Privacy Policy";
      case "disclaimer":
        return "Disclaimer";
      case "termsOfUse":
        return "Terms Of Use";
    }
    return "";
  };
  useEffect(() => {
    const fileChosen = getFile();
    if (fileChosen === null) return;
    fetch(fileChosen)
      .then((r) => r.text())
      .then((text) => {
        setFormattedText(text);
        window.location.href = "#";
      });
  }, [textType]);
  return (
    <Fragment>
      <div
        className="heading-title"
        style={{
          width: isMobile ? "90vw" : "986px",
          margin: "150px auto 0 auto",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        {getTitle()}
      </div>
      <div
        className="document-text-only"
        style={{
          width: isMobile ? "90vw" : "986px",
          margin: "50px auto 0 auto",
        }}
      >
        {formattedText}
      </div>
    </Fragment>
  );
}

export default TextOnly;

import React, { Suspense, useLayoutEffect, useState, Fragment } from "react";
import pageImg from "../../images/pageImg1.png";
import toTop from "../../images/toTop.svg";
import feedbackIcon from "../../images/feedbackIcon.svg";
import WaitlistPage from "./waitlistPage";
import NavigationBar from "../Custom/NavigationBar";
import CompanyBenefits from "./CompanyBenefits";
import Features from "./Features";
import WhoIsThisFor from "./WhoIsThisFor";
import Footer from "./Footer";
import RequestEarlyAccess from "./RequestEarlyAccess";
import { useAuth } from "../Contexts/AuthContext";
import Dock from "react-dock";
import TextOnly from "./TextOnly";
import { useEffect } from "react";
import { FeedbackFormComponent } from "feedbackplace-react";

const pagesData = [
  {
    id: 0,
    imgSrc: pageImg,
  },
];

const mobilePagesData = [
  {
    id: 0,
    imgSrc: pageImg,
  },
];

function FadeInSection(props) {
  const [isVisible, setVisible] = React.useState(true);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => setVisible(entry.isIntersecting));
      },
      { rootMargin: "-31% 0px", threshold: 0.05 }
    );
    observer.observe(domRef.current);
    // return () => observer.unobserve(domRef.current);
    document.documentElement.style.setProperty("--background", "#131415");
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
      id={"id" in props ? props.id : ""}
    >
      {props.children}
    </div>
  );
}

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export default function LandingPage() {
  const [width, height] = useWindowSize();
  const { earlyAccessPage, textOnly } = useAuth();
  const isMobile = width <= 1024;
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const data = isMobile ? mobilePagesData : pagesData;
  const [isDockVisible, setIsDockVisible] = useState(false);
  const [toTopVisible, setToTopVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setToTopVisible(true);
    } else if (scrolled <= 300) {
      setToTopVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <Fragment>
      <NavigationBar />
      {textOnly ? (
        <TextOnly isMobile={isMobile} />
      ) : (
        <Fragment>
          <Dock
            position={isMobile ? "bottom" : "top"}
            fluid={true}
            isVisible={earlyAccessPage}
            size={1}
            zIndex={isMobile ? 1 : 200}
            dimStyle={{ background: "rgba(19, 20, 21, 0.9)" }}
            dockStyle={{ background: "rgba(19, 20, 21, 0.8)" }}
          >
            <RequestEarlyAccess isMobile={isMobile} />
          </Dock>

          {data.map((page, key) => {
            return (
              <FadeInSection key={key}>
                <div
                  className="waitlist-page-wrapper"
                  id={key === 0 ? "topPage" : ""}
                  key={key}
                >
                  <Suspense fallback={<h1>Loading...</h1>}>
                    <WaitlistPage {...page} num={key} />
                  </Suspense>
                </div>
              </FadeInSection>
            );
          })}
          <FadeInSection id="features">
            <Features />
          </FadeInSection>
          <FadeInSection id="benefits">
            <CompanyBenefits />
          </FadeInSection>
          <FadeInSection id="usecases">
            <WhoIsThisFor />
          </FadeInSection>
        </Fragment>
      )}
      <Footer isMobile={isMobile} />

      {/* <a
        href={`https://github.com/login/oauth/authorize?scope=repo&client_id=1564d52264a31ff3dd19`}
      >
        Click here to begin!
      </a> */}

      <div
        className="back-to-top-btn-wrapper"
        style={{
          position: "fixed",
          bottom: "94px",
          right: "32px",
        }}
      >
        <div
          id="backToTop"
          className="back-to-top-btn"
          style={{
            opacity: toTopVisible ? "1" : "0",
            pointerEvents: toTopVisible ? "all" : "none",
            width: isMobile ? "48px" : "163px",
            borderRadius: isMobile ? "50%" : "8px",
          }}
          onClick={() => (window.location.href = "#")}
        >
          <img
            src={toTop}
            style={{
              width: "12px",
              marginRight: isMobile ? "0" : "16px",
            }}
          />
          {isMobile ? "" : "Back to top"}
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          bottom: "84px",
          right: "28px",
        }}
      >
        {isMobile ? (
          <Dock
            position={"bottom"}
            fluid={true}
            isVisible={feedbackVisible}
            size={1}
            zIndex={1}
            dimStyle={{ background: "rgba(19, 20, 21, 0.7)" }}
            dockStyle={{
              background: "rgba(19, 20, 21, 0)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            duration={0.5}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: "0",
                  left: "0",
                }}
                onClick={() => setFeedbackVisible(false)}
              />
              <FeedbackFormComponent
                token="private_jvCxusNZlu1rq8rz"
                postBgColor={"#222"}
                postTextcolor={"#999"}
                visible={feedbackVisible}
                maxWidth="unset"
              />
            </div>
          </Dock>
        ) : (
          <div style={{}}>
            <FeedbackFormComponent
              token="private_jvCxusNZlu1rq8rz"
              postBgColor={"#222"}
              postTextcolor={"#999"}
              visible={feedbackVisible}
            />
          </div>
        )}

        <div
          style={{
            width: "48px",
            height: "48px",
            backgroundColor: "#466ef9",
            borderRadius: "50%",
            position: "fixed",
            bottom: "26px",
            right: "32px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "white 0px 0px 20px -5px",
          }}
          onClick={() => setFeedbackVisible(!feedbackVisible)}
        >
          <img
            src={feedbackIcon}
            style={{
              width: "18px",
              height: "18px",
            }}
          />
        </div>
        {feedbackVisible && (
          <div
            style={{
              width: "100dvw",
              height: "100dvh",
              backgroundColor: "#11111122",
              position: "fixed",
              top: "0",
              left: "0",
            }}
            onClick={() => setFeedbackVisible(false)}
          />
        )}
      </div>
    </Fragment>
  );
}

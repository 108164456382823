import React, { useState, useEffect, Fragment } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { useAuth } from "../Contexts/AuthContext";
import { BackendServerURL } from "../Other/constants";
import backIcon from "../../images/back.svg";
import secureShield from "../../images/secureShield.svg";
import checkboxCheckedIcon from "../../images/checkboxChecked.svg";
import checkboxUncheckedIcon from "../../images/checkboxUnchecked.svg";

const SubscribeForm = (props) => {
  const { paymentMethod, buttonSecure, toSubscribe, priceId } = props;
  const history = useHistory();
  const {
    activeCard,
    addCard,
    updateCard,
    paymentMethods,
    paymentMethodsFetched,
  } = useAuth();
  const [messages, _setMessages] = useState("");
  const [username, setUsername] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(
    activeCard && paymentMethod && activeCard.id === paymentMethod.id
  );
  const [isSetActiveEnabled, setIsSetActiveEnabled] = useState(true);

  const onSetActiveCheckClicked = () => {
    if (isSetActiveEnabled) {
      setCheckboxChecked(!checkboxChecked);
    }
  };
  const setMessage = (message) => {
    _setMessages(message);
  };
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (
      (paymentMethodsFetched && paymentMethods.length === 0 && !activeCard) ||
      (activeCard && paymentMethod && activeCard.id === paymentMethod.id)
    ) {
      setCheckboxChecked(true);
      setIsSetActiveEnabled(false);
    } else {
      setCheckboxChecked(false);
      setIsSetActiveEnabled(true);
    }
  }, [paymentMethodsFetched, paymentMethods, activeCard, paymentMethod]);

  if (!stripe || !elements) {
    return "";
  }

  const convertToExpDate = (exp_month, exp_year) => {
    return `${
      exp_month < 10 ? "0" + exp_month.toString() : exp_month.toString()
    }/${exp_year.toString().slice(2)}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitClicked) return;
    setSubmitClicked(true);

    const name = username;
    const cardElement = elements.getElement(CardNumberElement);

    await stripe
      .createToken(cardElement, {
        name: username,
      })
      .then(async ({ token }) => {
        console.log("Received Stripe token:", token);

        if (paymentMethod) {
          let updateObject = {
            name: username,
            exp_month: token.card.exp_month,
            exp_year: token.card.exp_year,
          };
          await updateCard(paymentMethod.id, updateObject, checkboxChecked);
          history.push("/cards");
        } else {
          await addCard(token.id, checkboxChecked);
          if (toSubscribe) {
            history.push("/subscribe", {
              priceId: priceId,
            });
          } else {
            history.push("/cards");
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const inputBoxStyle = {
    iconColor: "#c4f0ff",
    color: "#FFFFFF",
    border: "1px solid #FFFFFF",
    fontWeight: "normal",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontSmoothing: "antialiased",
    ":WebkitAutofill": {
      color: "#8E8E8E",
    },
    "::placeholder": {
      color: "#8E8E8E",
    },
  };

  const nameInputBoxStyle = inputBoxStyle;
  nameInputBoxStyle.border = "2px solid #FFFFFF";
  nameInputBoxStyle.borderRadius = "8px";
  nameInputBoxStyle.padding = "14px 13px";
  nameInputBoxStyle.width = "100%";
  nameInputBoxStyle.backgroundColor = "#00000000";

  return (
    <form onSubmit={handleSubmit}>
      <div>
        Enter card number
        <br />
        <div className="card-input-wrapper">
          <CardNumberElement
            options={{
              style: {
                base: inputBoxStyle,
                iconStyle: { color: "#c4f0ff" },
              },
              placeholder: "XXXX-XXXX-XXXX-3753",
              showIcon: true,
            }}
          />
        </div>
      </div>
      <div>
        Expiry date
        <br />
        <div className="card-input-wrapper">
          <CardExpiryElement
            options={{
              style: {
                base: inputBoxStyle,
              },
              placeholder: "MM/YY",
            }}
          />
        </div>
      </div>
      <div>
        Enter CVV
        <br />
        <div className="card-input-wrapper">
          <CardCvcElement
            options={{
              style: {
                base: inputBoxStyle,
              },
            }}
          />
        </div>
      </div>
      <div>
        Name on the card
        <br />
        <input
          style={inputBoxStyle}
          placeholder="John Doe"
          type="text"
          id="name"
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div
        className={
          isSetActiveEnabled
            ? "setactive-checkbox-wrapper"
            : "setactive-checkbox-wrapper unselectabled-checkbox"
        }
        onClick={() => onSetActiveCheckClicked()}
      >
        <img
          src={checkboxChecked ? checkboxCheckedIcon : checkboxUncheckedIcon}
          style={{
            width: "18px",
            marginRight: "8px",
          }}
        />
        <span>Set as the active card</span>
      </div>
      <button
        type="submit"
        className={
          submitClicked
            ? "addCardButton addCardButtonDisabled"
            : "addCardButton"
        }
      >
        {buttonSecure ? (
          <Fragment>
            <img src={secureShield} style={{ marginRight: "14px" }} />
            Securely add
          </Fragment>
        ) : (
          "Add Card"
        )}
      </button>
    </form>
  );
};

const Subscribe = (props) => {
  let stripePromise = null;
  const [publishableKey, setPublishableKey] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [title, setTitle] = useState("New Card");
  const [buttonSecure, setButtonSecure] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (props.location.state && props.location.state.paymentMethod) {
      setPaymentMethod(props.location.state.paymentMethod);
      setTitle("Verify card details");
      setButtonSecure(false);
    }
  }, [props]);

  if (publishableKey) {
    stripePromise = loadStripe(publishableKey);
  } else {
    fetch(BackendServerURL + "/config")
      .then((r) => r.json())
      .then(({ publishableKey }) => {
        setPublishableKey(publishableKey);
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    document.documentElement.style.setProperty("--background", "#000");
    window.onunload = function () {
      document.documentElement.style.setProperty("--background", "#fff");
    };
  }, []);

  return (
    <div className="subscribe-wrapper">
      <div className="top-bar">
        <div
          onClick={() => {
            history.goBack();
          }}
          style={{
            color: "#fff",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          <img src={backIcon} />
          <span
            style={{
              marginLeft: "10px",
            }}
          >
            {title}
          </span>
        </div>
      </div>
      <Elements stripe={stripePromise}>
        <SubscribeForm
          {...props}
          paymentMethod={paymentMethod}
          buttonSecure={buttonSecure}
          toSubscribe={
            props.location.state && props.location.state.toSubscribe
              ? true
              : false
          }
          priceId={
            props.location.state && props.location.state.priceId
              ? props.location.state.priceId
              : null
          }
        />
      </Elements>
    </div>
  );
};

export default withRouter(Subscribe);

import React, { useEffect, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import { useWindowDimensions } from "../Contexts/WindowDimension";
import moreArrow from "../../images/more_arrow.svg";
import GetOnWaitlistElem from "./GetOnWaitlistElem";
import { FeedbackFormComponent } from "../FeedbackBtn";

function GetItem(props) {
  const { page, isMobile } = props;
  switch (page + 1) {
    case 1:
      return (
        <div>
          <div
            style={{
              paddingLeft: isMobile ? "9px" : "0px",
              paddingRight: isMobile ? "9px" : "0px",
              marginTop: "16px",
            }}
          >
            <div
              style={{
                fontSize: "36px",
                lineHeight: "36px",
                whiteSpace: "pre-wrap",
                fontWeight: "bold",
              }}
            >
              Boost growth: get{" "}
              <span
                style={{
                  color: "rgb(245, 158, 11)",
                }}
              >
                instant{" "}
              </span>
              <span
                style={{
                  textDecoration: "underline",
                  textUnderlineOffset: "6px",
                  textDecorationColor: "rgb(245, 158, 11)",
                }}
              >
                user feedback
              </span>
            </div>
            <div
              style={{
                fontSize: "14px",
                lineHeight: "18px",
                whiteSpace: "pre-wrap",
                marginTop: "16px",
              }}
            >
              Plug-n-use to gather feedback for your apps / softwares / websites
              / portfolios
            </div>
          </div>
        </div>
      );
    default:
      return "";
  }
}

function WaitlistPage(props) {
  const { imgSrc, num } = props;
  const history = useHistory();
  const { subscribed, addToWaitlist } = useAuth();
  const [waitlistBtnClick, setWaitlistBtnClick] = useState(false);
  const onWaitlistClick = () => {
    setWaitlistBtnClick(true);
  };
  const onEmailEnterClick = () => {
    const email = document.getElementById("emailInput").value;
    addToWaitlist("", email);
  };
  const { width, height } = useWindowDimensions();
  const isMobile = width <= 1024;
  useEffect(() => {
    return history.listen((location) => {
      if (location.action === "POP") {
        history.goBack();
      }
    });
  }, []);
  return (
    <Fragment>
      <div className="left-side-text-wrapper">
        <GetItem page={num} isMobile={isMobile} />
      </div>
      <GetOnWaitlistElem
        btnOnly
        wrapperStyle={{
          gridColumn: "1",
          gridRow: "2",
          gridRowStart: isMobile ? "4" : "2",
          height: isMobile ? "60px" : "52px",
        }}
        showFeaturesBtn={true}
        uid="waitlist"
      />
      <div className="image-wrapper">
        <img alt="" src={imgSrc} />
      </div>
      {isMobile ? null : (
        <a
          className="vertical-container breathe-animation"
          style={{
            height: "40px",
            width: "170px",
            alignItems: "center",
            position: "absolute",
            bottom: "75px",
            left: "46%",
            fontSize: "14px",
            color: "white",
            textDecoration: "none",
          }}
          href="#features"
        >
          <div>Show me the features</div>
          <img
            className="breathe-animation"
            src={moreArrow}
            style={{ transform: "rotate(90deg)", width: "12px" }}
          />
        </a>
      )}
    </Fragment>
  );
}

WaitlistPage.propTypes = {};

export default WaitlistPage;

import React, { useState, useEffect, useRef, Fragment } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import backIcon from "../../images/back.svg";
import Lottie from "react-lottie-player";
import lottieJson from "../../images/loadingBlack.json";
import plus from "../../images/plus.svg";
import checkSquare from "../../images/checkSquare.svg";
import swipeArrow from "../../images/swipeArrow.svg";
import trash from "../../images/trash.svg";
import edit from "../../images/edit.svg";
import errorIcon from "../../images/toastError.svg";
import checkIcon from "../../images/greenTick.svg";
import Dock from "react-dock";
import { getNameByProdId } from "../Other/constants";

const Account = () => {
  const {
    activeCard,
    paymentMethods,
    paymentMethodsFetched,
    setCardActive,
    deleteCard,
    subscribed,
    userSubscription,
    cancelSubscription,
    updateSubscription,
  } = useAuth();
  const ref = useRef();
  const [loadingAnim, setLoadingAnim] = useState(true);
  const [optionsVisible, _setOptionsVisible] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [startPositionx, setStartPositionX] = useState(null);
  const [removeClicked, setRemoveClicked] = useState(false);
  const [remove, setRemove] = useState(false);
  const [data, setData] = useState("");
  const history = useHistory();
  const [cancelSubscriptionButton, setCancelSubscriptionButton] =
    useState(false);
  const [changeSubscriptionButton, setChangeSubscriptionButton] =
    useState(false);
  const [subscriptionClicked, setSubscriptionClicked] = useState(false);
  const [optionsContentText, setOptionsContentText] = useState("");
  const [chooseCardToActivateScreen, setChooseCardToActivateScreen] =
    useState(false);

  useEffect(() => {
    document.documentElement.style.setProperty("--background", "#000");
    window.onunload = function () {
      document.documentElement.style.setProperty("--background", "#fff");
    };
  }, []);

  const resetRemoveButton = () => {
    document.getElementById("deleteIcon").classList.remove("activate-delete");
    document.getElementById("deleteIcon").style.transform = `translateX(0px)`;
    document.getElementById("deleteIcon").style.opacity = `1`;
    document.getElementById("remove-text").style.opacity = "1";
    document.getElementById("remove-text").innerText = "Remove this card";
    document.getElementById("remove-text").style.marginLeft = "56px";
    document.getElementById("remove-text").style.color = "#ff4a4a";
    document.getElementById("remove-button").style.background = "none";
    document
      .getElementById("options-content-text")
      .classList.add("hide-button");
    setOptionsContentText("");
    setRemove(false);
    setRemoveClicked(false);
  };

  const setOptionsVisible = (bool) => {
    _setOptionsVisible(bool);
    if (!bool) {
      resetRemoveButton();
      setSelectedCard(null);
      setSubscriptionClicked(false);
      setCancelSubscriptionButton(false);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (optionsVisible && ref.current && !ref.current.contains(e.target)) {
        setOptionsVisible(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [optionsVisible]);

  const onCardClick = async (e, card) => {
    console.log(e);
    if (chooseCardToActivateScreen) {
      document
        .getElementById("accountCardsListWrapper")
        .classList.add("inactive");
      await setCardActive(card.id);
      document
        .getElementById("accountCardsListWrapper")
        .classList.remove("inactive");
      setChooseCardToActivateScreen(false);
      setOptionsVisible(true);
      _onDeleteCardClicked();
      return;
    }
    setSelectedCard(card);
    document.getElementById("set-active-button").classList.remove("inactive");
    document.getElementById("update-button").classList.remove("inactive");
    document.getElementById("remove-button").classList.remove("inactive");
    document
      .getElementById("set-active-button")
      .classList.remove("hide-button");
    document.getElementById("update-button").classList.remove("hide-button");
    document.getElementById("remove-button").classList.remove("hide-button");
    if (card && card.id === activeCard.id) {
      if (
        subscribed === 1 &&
        userSubscription &&
        userSubscription.plan &&
        paymentMethods &&
        paymentMethods.length === 0
      ) {
        document.getElementById("remove-text").innerText =
          "Cancel subscription";
        document.getElementById("update-text").innerText =
          "Change subscription";
        setCancelSubscriptionButton(true);
        setChangeSubscriptionButton(true);
      } else {
        document.getElementById("update-text").innerText = "Update this card";
      }
      document.getElementById("set-active-button").classList.add("inactive");
    } else {
      document.getElementById("remove-text").innerText = "Remove this card";
      document.getElementById("update-button").classList.remove("inactive");
      document.getElementById("update-text").innerText = "Update this card";
      setCancelSubscriptionButton(false);
      setChangeSubscriptionButton(false);
    }
    setOptionsVisible(true);
  };

  const onCurrentSubscriptionClick = () => {
    setSubscriptionClicked(true);
    document.getElementById("set-active-button").classList.add("hide-button");
    document.getElementById("remove-text").innerText = "Cancel subscription";
    document.getElementById("update-text").innerText = "Change subscription";
    setCancelSubscriptionButton(true);
    setChangeSubscriptionButton(true);
    setOptionsVisible(true);
  };

  const onAddCardClick = () => {
    history.push("/add-card");
  };

  const onUpdateCardClicked = () => {
    if (changeSubscriptionButton) {
      onChangeSubscriptionClicked();
    } else {
      setOptionsVisible(false);
      history.push("/add-card", { paymentMethod: selectedCard });
    }
  };

  const onChangeSubscriptionClicked = async () => {
    if (
      userSubscription &&
      userSubscription.items &&
      userSubscription.items.data &&
      userSubscription.items.data.length > 0
    ) {
      setOptionsVisible(false);
      history.push("/prices");
      // await updateSubscription(userSubscription.items.data.at(0).id, "price_1IX445J4gYClsoqISqrZaKkR");
    } else {
      // Toast error message
    }
  };

  const onSetActiveCardClicked = async () => {
    setOptionsVisible(false);
    await setCardActive(selectedCard.id);
  };

  const onDeleteCardClicked = async () => {
    if (
      !cancelSubscriptionButton &&
      selectedCard.id === activeCard.id &&
      subscribed === 1
    ) {
      setChooseCardToActivateScreen(true);
      let card = selectedCard;
      setOptionsVisible(false);
      setSelectedCard(card);
      return;
    }
    _onDeleteCardClicked();
  };

  const _onDeleteCardClicked = async () => {
    if (remove) return;
    document.getElementById("deleteIcon").classList.add("activate-delete");
    document.getElementById("deleteIcon").style.transform = `translateX(0px)`;
    document.getElementById("remove-text").innerHTML =
      `<img src="${swipeArrow}" class="swipeArrow"/>` +
      (cancelSubscriptionButton ? "Swipe to unsubscribe" : "Swipe to remove");
    if (!startPositionx) {
      let offset = document
        .getElementById("deleteIcon")
        .getBoundingClientRect();
      setStartPositionX(offset.left);
    } else {
      document.getElementById("deleteIcon").style.transform = `translateX(0px)`;
    }
    setRemoveClicked(true);
    if (cancelSubscriptionButton) {
      document.getElementById("update-button").classList.add("hide-button");
      document.getElementById("set-active-button").classList.add("hide-button");
      document
        .getElementById("options-content-text")
        .classList.remove("hide-button");
      setOptionsContentText(
        "If you cancel your subscription. We will delete all your data and you won’t be able to use your dashboard\n\nConfirm using swipe gesture."
      );
    } else {
      document
        .getElementById("options-content-text")
        .classList.add("hide-button");
      setOptionsContentText("");
    }
  };

  const handleDrag = async (e) => {
    if (!removeClicked || remove) return;
    let diff = 0;
    if ("clientX" in e) {
      diff = e.clientX - startPositionx;
    } else {
      diff = e.targetTouches[0].clientX - startPositionx;
    }
    if (diff > 90) {
      document.getElementById("remove-text").style.opacity = "0";
    } else {
      document.getElementById("remove-text").style.opacity = "1";
    }
    if (!remove && diff >= 0 && diff <= 259) {
      e.target.style.transform = `translateX(${diff}px)`;
    }
    if (!remove && diff >= 259) {
      setRemove(true);
      document.getElementById("deleteIcon").style.opacity = `0`;
      document.getElementById("remove-text").style.opacity = "1";
      document.getElementById("remove-text").style.color = "#ffffff";
      document.getElementById("remove-text").style.marginLeft = "72px";
      document.getElementById("remove-button").style.background = "#ff4a4a";
      document.getElementById("remove-text").innerText =
        cancelSubscriptionButton ? "Unsubscribed" : "Removed";
      document
        .getElementById("options-content-text")
        .classList.add("hide-button");
      setOptionsContentText("");
      if (cancelSubscriptionButton) {
        await cancelSubscription();
      }
      await deleteCard(selectedCard.id);
      setOptionsVisible(false);
    }
  };

  const handleDragLeave = (e) => {
    if (!removeClicked || remove) return;
    e.target.style.transform = `translateX(0px)`;
    document.getElementById("remove-text").style.opacity = "1";
  };

  const getCard = (card, showCondition) => {
    return (
      <section
        className={
          selectedCard && optionsVisible && card.id == selectedCard.id
            ? "accountCardWrapper accountCardSelected"
            : "accountCardWrapper"
        }
        onClick={async (e) => {
          await onCardClick(e, card);
        }}
      >
        <div className="card-detail-wrapper">
          <span>xxxx-xxxx-xxxx-{card.last4}</span>
          <span className="card-detail-name">{card.name}</span>
          {card.cvc_check === "pass" || !showCondition ? (
            ""
          ) : (
            <span className="card-detail-error">
              Please re-enter your card details
            </span>
          )}
        </div>
        <div className={"all-card-settings " + card.brand.toLowerCase()} />
        <div className="card-status">
          {!showCondition ? (
            ""
          ) : card.cvc_check === "pass" ? (
            <img src={checkIcon} />
          ) : (
            <img src={errorIcon} />
          )}
        </div>
      </section>
    );
  };

  return (
    <div id="accountCardsListWrapper">
      {optionsVisible && removeClicked && !remove ? (
        <div className="exit-dock-wrapper">
          <div className="exit-dock-text">
            Tap anywhere else on the screen to go back to previous screen
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="account-top-bar">
        <div
          onClick={() => {
            chooseCardToActivateScreen
              ? setChooseCardToActivateScreen(false)
              : history.push("/");
          }}
          style={{
            color: "#fff",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          <img src={backIcon} />
          <span
            style={{
              marginLeft: "10px",
            }}
          >
            {chooseCardToActivateScreen
              ? "Activate another card first"
              : "Payments"}
          </span>
        </div>
      </div>
      <div
        style={{
          height: "10vh",
        }}
      ></div>
      {chooseCardToActivateScreen ? (
        <Fragment>
          {paymentMethods.length > 0 ? (
            <Fragment>
              <div className="payment-card-type-title">Other Cards: </div>
            </Fragment>
          ) : (
            ""
          )}
          {paymentMethods.length > 0 ? (
            paymentMethods.map((paymentMethod, index) => {
              return getCard(paymentMethod, false);
            })
          ) : activeCard ? (
            ""
          ) : !paymentMethodsFetched || loadingAnim ? (
            <div
              className="loading-wrapper"
              style={{
                height: "50vh",
              }}
            >
              <Lottie
                loop
                animationData={lottieJson}
                play
                onLoopComplete={() => {
                  if (paymentMethodsFetched) {
                    console.log("animation complete");
                    setLoadingAnim(false);
                  }
                }}
                style={{ width: 150, height: 150 }}
              />
              <span
                style={{
                  fontSize: "14px",
                }}
              >
                Gathering the data
              </span>
            </div>
          ) : (
            <div className="noPaymentTitle">
              {"You have no saved\npayment methods"}
            </div>
          )}

          <hr className="payment-card-type-division" />
          <div className="accountCardWrapper" style={{ paddingTop: "0" }}>
            <div className="select-card-alert">
              Select another card from the list to activate, before you remove
              your current active card
            </div>
          </div>
          <div
            style={{
              height: "15vh",
            }}
          ></div>
        </Fragment>
      ) : (
        <Fragment>
          {subscribed === 1 && userSubscription && userSubscription.plan ? (
            <Fragment>
              <div
                className={
                  "payment-card-type-title accountCardWrapper" +
                  (optionsVisible && subscriptionClicked
                    ? " accountCardSelected"
                    : "")
                }
                onClick={() => onCurrentSubscriptionClick()}
              >
                Current subscription :
                <span className="subscription-text">
                  {getNameByProdId(userSubscription.plan.product)}
                </span>
              </div>
              <hr className="payment-card-type-division" />
            </Fragment>
          ) : null}

          {activeCard ? (
            <Fragment>
              <div className="payment-card-type-title">Active Card: </div>
              {getCard(activeCard, true)}
            </Fragment>
          ) : (
            ""
          )}
          {paymentMethods.length > 0 ? (
            <Fragment>
              <hr className="payment-card-type-division" />
              <div className="payment-card-type-title">Other Cards: </div>
            </Fragment>
          ) : (
            ""
          )}
          {paymentMethods.length > 0 ? (
            paymentMethods.map((paymentMethod, index) => {
              return getCard(paymentMethod, false);
            })
          ) : activeCard ? (
            ""
          ) : !paymentMethodsFetched || loadingAnim ? (
            <div
              className="loading-wrapper"
              style={{
                height: "50vh",
              }}
            >
              <Lottie
                loop
                animationData={lottieJson}
                play
                onLoopComplete={() => {
                  if (paymentMethodsFetched) {
                    console.log("animation complete");
                    setLoadingAnim(false);
                  }
                }}
                style={{ width: 150, height: 150 }}
              />
              <span
                style={{
                  fontSize: "14px",
                }}
              >
                Gathering the data
              </span>
            </div>
          ) : (
            <div className="noPaymentTitle">
              {"You have no saved\npayment methods"}
            </div>
          )}
          <div
            style={{
              height: "15vh",
            }}
          ></div>
          <div className="addCardWrapper" hidden={!paymentMethodsFetched}>
            <button onClick={() => onAddCardClick()}>
              <img src={plus} style={{ marginRight: "16px" }} /> Add new card
            </button>
          </div>
        </Fragment>
      )}
      <Dock
        position="bottom"
        fluid={true}
        size={0.45}
        dimStyle={{
          background: "#0E0E0E",
          opacity: removeClicked ? "0.9" : "0.5",
          transition: "all 0.5s ease-in-out",
        }}
        dockStyle={{
          background: "#282A2D",
          width: "97%",
          minHeight: "420px",
          marginLeft: "1.5%",
          borderRadius: "10px",
        }}
        isVisible={optionsVisible}
      >
        <div style={{ width: "100%", height: "100%" }} ref={ref}>
          <div className="account-card-options-wrapper">
            <div
              className="account-card-option-wrapper"
              id="remove-button"
              onClick={() => onDeleteCardClicked()}
            >
              <img
                id="deleteIcon"
                src={trash}
                onTouchMove={(e) => handleDrag(e)}
                onTouchEnd={(e) => handleDragLeave(e)}
                onDrag={(e) => handleDrag(e)}
                onDragLeave={(e) => handleDragLeave(e)}
              />
              <span id="remove-text">Remove this card</span>
            </div>
            <div
              className="account-card-option-wrapper hide-button"
              id="options-content-text"
            >
              {optionsContentText}
            </div>
            <div
              className="account-card-option-wrapper"
              id="update-button"
              onClick={() => onUpdateCardClicked()}
              hidden={remove}
            >
              <img src={edit} />
              <span id="update-text">Update this card</span>
            </div>
            <div
              className="account-card-option-wrapper"
              id="set-active-button"
              onClick={() => onSetActiveCardClicked()}
              hidden={remove}
            >
              <img src={checkSquare} />
              <span>Set as active</span>
            </div>
          </div>
        </div>
      </Dock>
    </div>
  );
};

export default withRouter(Account);

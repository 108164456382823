import React, { useEffect, useState, Fragment } from "react";
import { useAuth } from "../Contexts/AuthContext";
import { useWindowDimensions } from "../Contexts/WindowDimension";
import moreArrow from "../../images/more_arrow.svg";
import closeImg from "../../images/close.svg";
import moneyBag from "../../images/moneyBag.png";

function GetOnWaitlistElem(props) {
  const {
    btnOnly,
    wrapperStyle,
    hideCross,
    noInitialBtn,
    uid,
    mobileEarlyAccess,
    hideArrow,
    setIsNavbarVisible,
    showFeaturesBtn,
  } = props;
  const { width, height } = useWindowDimensions();
  const { addToWaitlist, setEarlyAccessPage, earlyAccessPage } = useAuth();
  const [currentTileIdx, setCurrentTileIdx] = useState(2);
  const [email, setEmail] = useState("");
  const [errorState, setErrorState] = useState(false);
  const [waitlistBtnClick, setWaitlistBtnClick] = useState(noInitialBtn);
  const [attentionText, setAttentionText] = useState("Limited time offer.");
  const [hintText, _setHintText] = useState(
    "Claim a discounted price by requesting early access."
  );
  const uniqueId = "inputContainer-" + uid;
  const setHintText = (txt) => {
    _setHintText(txt);
    setAttentionText("");
  };
  const onWaitlistBtnClick = () => {
    setWaitlistBtnClick(true);
    setHintText(
      "This email ID will be used to avail the discount during pubic release"
    );
  };
  const onCancelBtnClick = () => {
    setWaitlistBtnClick(false);
    setHintText("Claim a discounted price by requesting early access.");
    setAttentionText("Limited time offer. ");
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<Fragment>()[\]\\.,;:\s@"]+(\.[^<Fragment>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const onEmailEnterClick = () => {
    // check if email is correct
    if (validateEmail(email)) {
      addToWaitlist("", email);
      setHintText(
        "We have received your request for early access. We will get back to you shortly when we have an update :)"
      );
      try {
        if (btnOnly) {
          document.getElementById(uniqueId).style.whiteSpace = "pre-wrap";
          document.getElementById(uniqueId).style.transform =
            "translateX(-85px)";
          document.getElementById(uniqueId).style.border = "0";
          document.getElementById(uniqueId).innerHTML =
            "We have received your request for early access.\nWe will get back to you shortly when we have an update :)";
        } else {
          document.getElementById(uniqueId).style.transition =
            "opacity 0.2s ease-in-out, width 0.4s ease-in-out";
          document.getElementById(uniqueId).style.opacity = "0";
          document.getElementById(uniqueId).style.width = "0";
        }

        document.getElementById(uniqueId + "hintText").style.marginLeft = "0";
        document.getElementById(uniqueId + "hintText").style.transform =
          "translateX(-20px)";
      } catch (error) {}
      if (mobileEarlyAccess) {
        document.getElementById("earlyAccessMobileSubmitBtn").style.display =
          "none";
        document.getElementById(
          "earlyAccessMobileLtdOfferHeading"
        ).style.display = "none";
        document.getElementById("earlyAccessMobileHintText").style.transform =
          "translateY(-200px)";
      }
    } else {
      setErrorState(true);
      setHintText(
        "Enter a valid email ID and click ‘submit’ or press enter/ return button"
      );
    }
  };
  const desktopInputField = () => {};
  const isMobile = width <= 1024;

  useEffect(() => {
    if (waitlistBtnClick) {
      setErrorState(false);
      if (email.length > 0) {
        setHintText("Press enter/return or click ‘submit’");
      } else {
        setHintText(
          "This email ID will be used to avail the discount during pubic release"
        );
      }
    }
  }, [email]);
  useEffect(() => {
    if (!isMobile && document.getElementById("getOnWaitlistEmail")) {
      document.getElementById("getOnWaitlistEmail").focus();
    }
  }, [earlyAccessPage]);

  useEffect(() => {
    if (isMobile) return;
    if (noInitialBtn) onWaitlistBtnClick(noInitialBtn);
  }, [noInitialBtn]);

  useEffect(() => {
    if (!document.getElementById(uniqueId)) return;
    if (waitlistBtnClick) {
      if (errorState) {
        document.getElementById(uniqueId).classList.add("error-border");
      } else {
        document.getElementById(uniqueId).classList.remove("error-border");
      }
    } else {
      document.getElementById(uniqueId).classList.remove("error-border");
    }
  }, [errorState]);

  if (mobileEarlyAccess) {
    return (
      <div
        className="vertical-container"
        style={{
          alignItems: "center",
          width: "310px",
          height: "254px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "110px",
        }}
      >
        <div
          id={uniqueId}
          className="transparent-button"
          style={{
            width: "300px",
          }}
        >
          <div style={{ width: "100%", height: "52px", position: "relative" }}>
            <input
              className="transparent-input"
              value={email}
              style={{
                width: "290px",
                height: "50px",
                paddingLeft: "12px",
              }}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  onEmailEnterClick();
                }
              }}
            />
            <div
              className={
                "floating-label floating-label-waitlist emailInput" +
                (email.length > 0
                  ? " emailInputFocus floating-label-waitlist-focus"
                  : "")
              }
              style={{ marginLeft: "8px" }}
            >
              <span style={email.length > 0 ? { display: "none" } : {}}>
                Enter your email ID here
              </span>
            </div>
          </div>
        </div>

        <div
          className="join-waitlist-button join-waitlist-button-hover"
          style={{
            width: "300px",
          }}
          id="earlyAccessMobileSubmitBtn"
          onClick={() => onEmailEnterClick()}
        >
          Submit
        </div>
        <div
          id="earlyAccessMobileHintText"
          className="sub-heading"
          style={{
            marginTop: "35px",
            textAlign: "center",
            fontSize: "10px",
            transition: "all 0.5s ease-in-out",
          }}
        >
          {hintText}
        </div>
        <div
          id="earlyAccessMobileLtdOfferHeading"
          className="sub-heading"
          style={{ marginTop: "15px", textAlign: "center" }}
        >
          <font color="#FFC940">Limited time offer. </font> <br></br>Claim a
          discounted price by requesting early access.
        </div>
      </div>
    );
  }

  if (isMobile) {
    return (
      <div>
        {showFeaturesBtn && (
          <div
            className="transparent-button  transparent-button-hover"
            style={{
              height: "60px",
              width: "90vw",
              alignItems: "center",
              fontSize: "14px",
              textDecoration: "none",
              gridArea: "3 / 1 / auto / auto",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => (window.location.href = "#features")}
          >
            <div>Show me the features</div>
          </div>
        )}
        <div
          className="join-waitlist-button join-waitlist-button-hover"
          style={{
            width: "90vw",
            height: "60px",
            marginTop: "16px",
            marginLeft: "auto",
            marginRight: "auto",
            ...wrapperStyle,
          }}
          onClick={() => {
            setEarlyAccessPage(true);
            if (setIsNavbarVisible) setIsNavbarVisible(false);
          }}
        >
          <div className="request-text">Get early access</div>
          <img
            src={moreArrow}
            style={{
              marginLeft: "16px",
              width: "12px",
              paddingTop: "1px",
              display: hideArrow ? "none" : "",
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      <div
        className="horizontal-container"
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          width: btnOnly ? "unset" : "992px",
          height: "102px",
          marginLeft: btnOnly ? "0" : "auto",
          marginRight: btnOnly ? "0" : "auto",
          marginTop: btnOnly ? "0" : "80px",
          ...wrapperStyle,
        }}
      >
        <div
          id={uniqueId}
          className={
            waitlistBtnClick
              ? "join-waitlist-button  transparent-button" +
                (waitlistBtnClick ? " " : " join-waitlist-button-hover")
              : "join-waitlist-button" +
                (waitlistBtnClick ? " " : " join-waitlist-button-hover")
          }
          onClick={waitlistBtnClick ? null : () => onWaitlistBtnClick()}
          style={
            isMobile
              ? { width: "100%" }
              : waitlistBtnClick
              ? { width: "524px", cursor: "default" }
              : {}
          }
        >
          {waitlistBtnClick ? (
            <div
              className="horizontal-container"
              style={{
                alignItems: "center",
                width: isMobile ? "280px" : "500px",
                height: "31px",
              }}
            >
              <div
                style={{ width: "100%", height: "52px", position: "relative" }}
              >
                <input
                  id="getOnWaitlistEmail"
                  className="transparent-input"
                  style={{
                    marginRight: "5px",
                    marginRight: "8px",
                    height: "50px",
                    width: "inherit",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      onEmailEnterClick();
                    }
                  }}
                />
                <div
                  className={
                    "floating-label floating-label-waitlist emailInput" +
                    (email.length > 0
                      ? " emailInputFocus floating-label-waitlist-focus"
                      : "")
                  }
                  style={{ marginLeft: "4px" }}
                >
                  <span style={email.length > 0 ? { display: "none" } : {}}>
                    Enter your email ID here
                  </span>
                </div>
              </div>

              {hideCross ? (
                ""
              ) : (
                <img
                  src={closeImg}
                  style={{
                    padding: "8px",
                    paddingRight: "0",
                    marginLeft: "auto",
                    cursor: "pointer",
                  }}
                  onClick={() => onCancelBtnClick()}
                />
              )}
              <div
                className="join-waitlist-button join-waitlist-button-hover"
                style={
                  email.length === 0
                    ? { width: "0px", display: "none" }
                    : {
                        width: "87px",
                        height: "32px",
                        marginLeft: "16px",
                        marginRight: "-4px",
                        borderRadius: "4px",
                      }
                }
                onClick={() => onEmailEnterClick()}
              >
                submit
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="request-text">Get early access</div>
              <img
                src={moreArrow}
                style={{
                  marginLeft: "16px",
                  width: "12px",
                  paddingTop: "1px",
                  display: hideArrow ? "none" : "",
                }}
              />
            </Fragment>
          )}
        </div>
        {btnOnly ? (
          ""
        ) : (
          <Fragment>
            <div
              id={uniqueId + "hintText"}
              className="hint-text"
              style={{ marginLeft: "40px", transition: "all 0.3s ease-in-out" }}
            >
              <font color="#FFC940" style={{ marginRight: "0.2em" }}>
                {attentionText}{" "}
              </font>
              {" " + hintText}
            </div>
            <img
              src={moneyBag}
              style={
                waitlistBtnClick
                  ? { width: 0, transition: "all 0.4s" }
                  : {
                      width: "102px",
                      transition: "all 0.4s",
                      marginLeft: "120px",
                    }
              }
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

export default GetOnWaitlistElem;

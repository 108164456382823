import React from "react";
import { Route, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

export default function CustomRoute({ component: Component, ...rest }) {
  if ("private" in rest) {
    return <PrivateRoute {...rest} component={Component} />;
  }

  return <Route {...rest} component={Component}></Route>;
}

import React, { Fragment } from "react";
import logo from "../../images/feedbackLogo.png";
import discord from "../../images/discord.svg";
import twitter from "../../images/twitter.svg";
import mail from "../../images/mail.svg";
import { useAuth } from "../Contexts/AuthContext";

function Footer(props) {
  const { isMobile } = props;
  const { landingPageSwitch } = useAuth();

  return (
    <Fragment>
      <div
        style={{
          width: "100%",
          height: isMobile ? "562px" : "250px",
          background: "#232628",
          marginTop: "120px",
        }}
        className="footer-text"
      >
        <div
          className={isMobile ? "vertical-container" : "horizontal-container"}
          style={{
            width: isMobile ? "unset" : "986px",
            margin: "60px auto",
            paddingTop: "60px",
            alignItems: isMobile ? "center" : "",
          }}
        >
          <div
            style={{
              marginTop: "-3px",
            }}
          >
            <img
              src={logo}
              style={{ width: "198px", cursor: "pointer" }}
              onClick={() => landingPageSwitch("#")}
            />
            <div
              className="vertical-container"
              style={{
                marginTop: isMobile ? "40px" : "19px",
                height: "80px",
                alignItems: isMobile ? "center" : "",
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => landingPageSwitch("#features")}
              >
                Features
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => landingPageSwitch("#benefits")}
              >
                Benefits
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => landingPageSwitch("#usecases")}
              >
                Who is it for?
              </div>
            </div>
          </div>

          {isMobile ? (
            <Fragment>
              <div
                style={{
                  background: "#505050",
                  color: "#505050",
                  borderColor: "#505050",
                  height: "1px",
                  margin: "40px 21px",
                  width: "332px",
                }}
              />
              <div
                style={{
                  cursor: "pointer",
                  height: "35px",
                }}
                onClick={() => landingPageSwitch("privacyPolicy", true)}
              >
                <div>Privacy Policy</div>
              </div>
              <div
                style={{ cursor: "pointer", height: "35px" }}
                onClick={() => landingPageSwitch("termsOfUse", true)}
              >
                <div>Terms of use</div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div
                style={{
                  marginLeft: isMobile ? "0" : "252px",
                  cursor: "pointer",
                  height: "35px",
                }}
                onClick={() => landingPageSwitch("termsOfUse", true)}
              >
                <div>Terms of use</div>
              </div>
              <div
                style={{ cursor: "pointer", height: "35px" }}
                onClick={() => landingPageSwitch("privacyPolicy", true)}
              >
                <div>Privacy Policy</div>
              </div>
            </Fragment>
          )}

          <div
            style={{ cursor: "pointer", height: "35px" }}
            onClick={() => landingPageSwitch("disclaimer", true)}
          >
            <div>Disclaimer</div>
          </div>
          <div
            className="horizontal-container"
            style={{
              width: isMobile ? "212px" : "122px",
              marginTop: isMobile ? "40px" : "-3px",
              display: "flex",
              justifyContent: isMobile ? "center" : "space-between",
            }}
          >
            {/* <img
              src={twitter}
              style={{
                width: isMobile ? "60px" : "30px",
                height: isMobile ? "60px" : "30px",
                cursor: "pointer",
              }}
              onClick={() =>
                window.open("https://twitter.com/feedback_place", "_blank")
              }
            />
            <img
              src={discord}
              style={{
                width: isMobile ? "60px" : "30px",
                height: isMobile ? "60px" : "30px",
                cursor: "pointer",
              }}
            /> 
            <img
              src={mail}
              style={{
                width: isMobile ? "60px" : "30px",
                height: isMobile ? "60px" : "30px",
                cursor: "pointer",
              }}
              onClick={() =>
                (window.location.href = "mailto:hello@feedback.place")
              }
            />*/}
          </div>
        </div>
      </div>
      {isMobile ? (
        <div
          className="horizontal-container under-footer-text "
          style={{ height: "27px" }}
        ></div>
      ) : (
        <div
          className="horizontal-container under-footer-text "
          style={{ width: "986px", margin: "0px auto", padding: "40px 0" }}
        >
          <div>
            All rights reserved. Feedback Place<sup>TM</sup>
          </div>
          <div>made with love and empathy for startup teams</div>
        </div>
      )}
    </Fragment>
  );
}

export default Footer;

import React, { useState, Fragment } from "react";
import { useAuth } from "../Contexts/AuthContext";
import { useWindowDimensions } from "../Contexts/WindowDimension";
import Dock from "react-dock";
import GetOnWaitlistElem from "../LandingPage/GetOnWaitlistElem";

function NavigationBar(props) {
  const { hideLogin, hidePricing } = props;
  const { setEarlyAccessPage, earlyAccessPage, landingPageSwitch } = useAuth();
  const { width, height } = useWindowDimensions();
  const isMobile = width <= 1024;
  const [isVisible, _setIsVisible] = useState(false);

  const setIsVisible = (val) => {
    if (earlyAccessPage) {
      setEarlyAccessPage(false);
    } else {
      _setIsVisible(val);
    }
  };

  const getNavigationBarLinks = () => {
    return (
      <Fragment>
        <li
          onClick={() => {
            landingPageSwitch("#features");
            setIsVisible(false);
          }}
        >
          <div style={{ textDecoration: "none", color: "white" }}>
            <span className="menu-options-title">Features</span>
          </div>
        </li>
        <li
          onClick={() => {
            landingPageSwitch("#benefits");
            setIsVisible(false);
          }}
        >
          <div style={{ textDecoration: "none", color: "white" }}>
            <span className="menu-options-title">Benefits</span>
          </div>
        </li>

        <li
          onClick={() => {
            landingPageSwitch("#usecases");
            setIsVisible(false);
          }}
        >
          <div style={{ textDecoration: "none", color: "white" }}>
            <span className="menu-options-title">Use Cases</span>
          </div>
        </li>

        <li
          style={isMobile ? { display: "none" } : {}}
          onClick={() => {
            setEarlyAccessPage(true);
            setIsVisible(false);
          }}
        >
          <span style={{ textDecoration: "none", color: "#6084FF" }}>
            <span className="menu-options-title">
              <u>Get early access</u>
            </span>
          </span>
        </li>
      </Fragment>
    );
  };

  if (!isMobile) {
    return (
      <div className="top-bar-wrapper" id="front-page-top-bar">
        <div className="top-bar-sub-container">
          <div className="website-title" onClick={() => landingPageSwitch("#")}>
            Feedback place
          </div>
          <ul className="top-bar-links">{getNavigationBarLinks()}</ul>
        </div>
      </div>
    );
  } else {
    return (
      <Fragment>
        <Dock
          position="right"
          fluid={true}
          isVisible={isVisible}
          size={1}
          zIndex={1}
          dimStyle={{ background: "rgba(19, 20, 21, 0.9)" }}
          dockStyle={{ background: "rgba(19, 20, 21, 0.8)" }}
        >
          <ul className="side-navbar-links">{getNavigationBarLinks()}</ul>
          <GetOnWaitlistElem
            btnOnly
            wrapperStyle={{
              gridColumn: "1",
              gridRow: "2",
              gridRowStart: "3",
              height: "60px",
              position: "absolute",
              bottom: "20px",
              left: "5vw",
            }}
            hideArrow
            uid="waitlist"
            setIsNavbarVisible={setIsVisible}
          />
        </Dock>
        <div className="top-bar-wrapper" id="front-page-top-bar">
          <div className="website-title" onClick={() => landingPageSwitch("#")}>
            Feedback place
          </div>
          <div
            className={
              "bar-container menu-button" +
              (isVisible || earlyAccessPage ? " change" : "")
            }
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => {
              setIsVisible(!isVisible);
            }}
          >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </div>
      </Fragment>
    );
  }
}

NavigationBar.propTypes = {};

export default NavigationBar;

import React, { useState, useRef, Fragment } from "react";
import { useEffect } from "react";
import Select from "react-select";
import { useAuth } from "../Contexts/AuthContext";
import CustomDropdown from "../Custom/CustomDropdown";
import Cookies from "js-cookie";

function FeedbackItemCard(props) {
  const {
    id,
    feedbackText,
    feedbackType,
    date,
    priorityVal,
    priorityTypes,
    actionOptions,
    defaultBgColor,
  } = props;

  const [visible, setVisible] = useState(false);
  const ref = useRef();
  const { currentUserData, apiCallUrl, localAuthTokenSave, addToastMessage } =
    useAuth();
  const [dateFormatted, setDateFormatted] = useState("N/A");
  const [priority, setPriority] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const ColorOptions = {};
  const setPriorityById = (id) => {
    for (let index = 0; index < priorityTypes.length; index++) {
      const element = priorityTypes[index];
      if (element["_id"] === id) {
        setPriority(element);
        break;
      }
    }
  };

  useEffect(() => {
    setDateFormatted(convertDate(date));
  }, [date]);

  useEffect(() => {
    if (priorityVal !== null) {
      setPriorityById(priorityVal);
    }
  }, [priorityVal]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (visible && ref.current && !ref.current.contains(e.target)) {
        setVisible(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [visible]);

  function convertDate(inputFormat) {
    // dd/mm/yyyy
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  const updatePriorityForFeedback = async (priority) => {
    var priorityId = priority["_id"];
    await fetch(apiCallUrl + "/feedbacks/" + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + Cookies.get(localAuthTokenSave),
      },
      body: JSON.stringify({ priority: priorityId, text: "asdqwe" }),
    })
      .then((r) => {
        if (r.status === 200) {
          return r.json();
        } else {
          addToastMessage("Something went wrong", "warning");
        }
      })
      .then((res) => {
        console.log(res);
        addToastMessage("Priority set successfully", "success");
        setPriorityById(priorityId);
      })
      .catch((e) => addToastMessage("Priority was not set", "error"));
  };

  const getColorByPriority = (val) => {
    console.log(val);
    return {};
  };

  return (
    <div
      className={
        "card-background item-horizontal-wrapper " +
        (defaultBgColor ? "card-bg" : "alt-card-bg") +
        (disabled ? " item-disabled" : "")
      }
      id={id}
    >
      <div className="select-box-item"></div>
      <div className="date-text">{dateFormatted}</div>
      <div className="feedback-text">{feedbackText}</div>
      <div className="feedabck-type-text ">
        {feedbackType !== null ? (
          <div
            className="pill-background"
            style={{ color: feedbackType["color"] }}
          >
            {feedbackType["description"]}
          </div>
        ) : (
          <Fragment></Fragment>
        )}
      </div>
      <div className="priority-text">
        <CustomDropdown
          listItems={priorityTypes}
          defaultItem={<div className="pill-background">Select</div>}
          initSelectedItem={priority}
          uid={id}
          selectBg={{
            padding: "1px 0",
          }}
          onItemSelected={(val) => updatePriorityForFeedback(val)}
          pillShaped
        />
      </div>
      <div className="action-container">
        <Select
          value={null}
          options={actionOptions}
          components={{
            IndicatorSeparator: () => null,
          }}
          styles={{
            control: (base) => ({
              ...base,
              width: "40px",
            }),
            valueContainer: (base) => ({
              ...base,
              width: "0px",
              padding: "0",
            }),
          }}
          onChange={(val) => {
            val.click(id);
            if (val.hasOwnProperty("disableLater") && val["disableLater"]) {
              setDisabled(true);
            }
          }}
          placeholder=""
        />
      </div>
    </div>
  );
}

FeedbackItemCard.propTypes = {};

export function FeedbackHeaderItemCard(props) {
  return (
    <div className="card-background item-horizontal-wrapper">
      <div className="select-box-item"></div>
      <div className="date-text header-item">Date</div>
      <div className="feedback-text header-item">Feedback</div>
      <div className="feedabck-type-text header-item">Type</div>
      <div className="priority-text header-item">Priority</div>
      {/* <div className="action-container header-item">Action</div> */}
    </div>
  );
}

export default FeedbackItemCard;

import React, { useEffect, Fragment } from "react";
import GetOnWaitlistElem from "./GetOnWaitlistElem";
import { useAuth } from "../Contexts/AuthContext";
import cross from "../../images/cross.svg";

function RequestEarlyAccess(props) {
  const { isMobile } = props;
  const { setEarlyAccessPage, earlyAccessPage } = useAuth();

  useEffect(() => {
    if (earlyAccessPage && document.getElementById("emailInput")) {
      document.getElementById("emailInput").focus();
    }
  }, [earlyAccessPage]);

  if (isMobile) {
    return (
      <GetOnWaitlistElem
        noInitialBtn
        mobileEarlyAccess
        uid="requestEarlyMobile"
      />
    );
  }
  return (
    <div
      style={{
        width: "1020px",
        height: "250px",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "50px",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "460px",
        }}
      >
        <div className="heading-title">Request for early access</div>
        <div className="sub-heading" style={{ marginTop: "24px" }}>
          <font color="#FFC940">Limited time offer.</font> Claim a discounted
          price by requesting early access.
        </div>
      </div>
      <div
        style={{
          cursor: "pointer",
          position: "absolute",
          top: "50px",
          right: "0",
        }}
        className="sub-heading"
        onClick={() => setEarlyAccessPage(false)}
      >
        <img src={cross} style={{ marginRight: "4px", paddingBottom: "1px" }} />{" "}
        close
      </div>
      <GetOnWaitlistElem
        hideCross
        noInitialBtn
        uid="requestEarly"
        wrapperStyle={{
          marginLeft: "0",
        }}
      />
    </div>
  );
}

RequestEarlyAccess.propTypes = {};

export default RequestEarlyAccess;

import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import greenTick from "../../images/greenTick.svg";
import redCross from "../../images/redCross.svg";
import { useHistory } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import circle1 from "../../images/circle1.svg";
import circle2 from "../../images/circle2.svg";
import bellIcon from "../../images/bellIcon.svg";
import star from "../../images/star.svg";
import NavigationBar from "../Custom/NavigationBar";
import { Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import { useWindowDimensions } from "../Contexts/WindowDimension";
import { StarterPrice } from "../Other/constants";

const Prices = (props) => {
  const [priceLookupKey, setPriceLookupKey] = useState(null);
  const [monthly, setMonthly] = useState(true);
  let history = useHistory();
  const { width, height } = useWindowDimensions();
  const isDesktop = width > 901;
  const [waitlistMode, setWaitlistMode] = useState(false);
  const [waitlistSelected, setWaitlistSelected] = useState("");
  const { paymentMethodsFetched, paymentMethods, activeCard } = useAuth();

  useEffect(() => {
    document.documentElement.style.setProperty("--background", "#fff");
  }, []);

  const getPriceTile = (monthly, idx) => {
    switch (idx) {
      case 0:
        return (
          <div className="price-tile">
            <div className="price-title">
              <span>Starter</span>
              <span>{monthly ? "$13.99 / mo" : "$166.99 / yr"}</span>
            </div>
            <div className="price-details">
              <ul>
                <li>
                  Full list of investors
                  <img src={greenTick} className="green-tick" />
                </li>
                <li>
                  Bookmark list - upto 3 lists
                  <img src={greenTick} className="green-tick" />
                </li>
                <li>
                  Investor filter
                  <img src={greenTick} className="green-tick" />
                </li>
                <li>
                  Email/ Phone No.
                  <img src={greenTick} className="green-tick" />
                </li>
                <li>
                  Advanced bookmark features
                  <img src={redCross} className="red-cross" />
                </li>
                <li>
                  Advanced investment details
                  <img src={redCross} className="red-cross" />
                </li>
                <li>
                  CRM Integration
                  <img src={redCross} className="red-cross" />
                </li>
              </ul>
            </div>
            <div>
              <button
                className="price-button"
                onClick={() => setPriceLookupKey(StarterPrice.priceId)}
              >
                Select
              </button>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="price-tile">
            <div className="price-title">
              <span>Plus</span>
              <div className="coming-soon">
                <div className="coming-soon-triangle"></div>
                <div className="coming-soon-title">coming soon</div>
              </div>
            </div>
            <div className="price-details">
              <ul>
                <li>
                  Full list of investors
                  <img src={greenTick} className="green-tick" />
                </li>
                <li>
                  Bookmark list - upto 3 lists
                  <img src={greenTick} className="green-tick" />
                </li>
                <li>
                  Investor filter
                  <img src={greenTick} className="green-tick" />
                </li>
                <li>
                  Email/ Phone No.
                  <img src={greenTick} className="green-tick" />
                </li>
                <li>
                  Advanced bookmark features
                  <img src={greenTick} className="green-tick" />
                </li>
                <li>
                  Advanced investment details
                  <img src={greenTick} className="green-tick" />
                </li>
                <li>
                  CRM Integration
                  <img src={redCross} className="red-cross" />
                </li>
              </ul>
            </div>
            <div className="waitlist-btn-wrapper">
              <button
                className="price-button waitlistBtn"
                onClick={() => {
                  setWaitlistSelected("Plus");
                  getAnimatedtile(1);
                }}
              >
                Waitlist
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="price-tile">
            <div className="price-title">
              <span>Pro</span>
              <div className="coming-soon">
                <div className="coming-soon-triangle"></div>
                <div className="coming-soon-title">coming soon</div>
              </div>
            </div>
            <div className="price-details">
              <ul>
                <li>
                  Full list of investors
                  <img src={greenTick} className="green-tick" />
                </li>
                <li>
                  Bookmark list - upto 3 lists
                  <img src={greenTick} className="green-tick" />
                </li>
                <li>
                  Investor filter
                  <img src={greenTick} className="green-tick" />
                </li>
                <li>
                  Email/ Phone No.
                  <img src={greenTick} className="green-tick" />
                </li>
                <li>
                  Advanced bookmark features
                  <img src={greenTick} className="green-tick" />
                </li>
                <li>
                  Advanced investment details
                  <img src={greenTick} className="green-tick" />
                </li>
                <li>
                  CRM Integration
                  <img src={greenTick} className="green-tick" />
                </li>
              </ul>
            </div>
            <div className="waitlist-btn-wrapper">
              <button
                className="price-button waitlistBtn"
                onClick={() => {
                  setWaitlistSelected("Pro");
                  getAnimatedtile(2);
                }}
              >
                Waitlist
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="price-tile">
            <div className="price-title">
              <span>Join {waitlistSelected} Waitlist</span>
            </div>
            <div className="price-tile-content-wrapper">
              <div>
                {
                  "I want access to Starter and be notified to be upgraded with no extra charge"
                }
              </div>
              <button
                className="free-upgrade-btn"
                onClick={() =>
                  history.push({
                    pathname: "/login",
                    state: { fromFreeUpgrade: true },
                  })
                }
              >
                <img src={star} style={{ marginRight: "16px" }} />
                Free Upgrade
              </button>
              <div style={{ width: "100%" }}>
                {"I just want to be notified"}
              </div>
              <button
                className="just-notify-btn"
                onClick={() =>
                  history.push({
                    pathname: "/waitlist",
                  })
                }
              >
                <img src={bellIcon} style={{ marginRight: "16px" }} />
                Just Notify
              </button>
            </div>
          </div>
        );
      default:
        return "";
    }
  };

  const getAnimatedtile = (idx) => {
    // hide other price-tiles
    if (isDesktop) {
      for (let i = 0; i < 3; i++) {
        if (i !== idx) {
          document
            .getElementsByClassName("price-tile")
            [i].classList.add("fadeOut");
        }
      }
      setTimeout(() => {
        for (let i = 0; i < 3; i++) {
          if (i !== idx) {
            document.getElementsByClassName("price-tile")[i].style.display =
              "none";
          }
        }
      }, 500);
    } else {
      setWaitlistMode(true);
      return;
    }
    document
      .getElementsByClassName("coming-soon-triangle")
      [idx - 1].classList.add("coming-soon-whiteBg");
    document
      .getElementsByClassName("coming-soon-title")
      [idx - 1].classList.add("coming-soon-title-whiteBg");
    document
      .getElementsByClassName("price-tile")
      [idx].classList.add("price-tile-custom");
    document.getElementsByClassName("waitlist-btn-wrapper")[idx - 1].innerHTML =
      "Select an option on the right";
    setTimeout(() => {
      setWaitlistMode(true);
    }, 700);
  };

  if (priceLookupKey) {
    if (!paymentMethodsFetched) {
      // Toast message error
      history.push("/");
    }
    if (paymentMethods.length === 0 && !activeCard) {
      history.push("/add-card", { toSubscribe: true, priceId: priceLookupKey });
    } else {
      history.push("/subscribe", { priceId: priceLookupKey });
    }
  }

  return (
    <div>
      <NavigationBar hideLogin hidePricing />
      <div className="pricing-main-wrapper">
        <div className="pricing-main-title">Pricing</div>
        <div className="pricing-slider" onClick={() => setMonthly(!monthly)}>
          <div
            className={
              monthly
                ? "background-slider"
                : "background-slider background-slider-right"
            }
          >
            {/* {monthly ? "monthly":"annual"} */}
          </div>
          <div className="pricing-slider-text">
            <span
              style={
                monthly
                  ? {
                      color: "#fff",
                    }
                  : {}
              }
            >
              monthly
            </span>
            <span
              style={
                !monthly
                  ? {
                      color: "#fff",
                    }
                  : {}
              }
            >
              annual
            </span>
          </div>
        </div>
      </div>
      {isDesktop ? (
        <div className="price-list">
          {getPriceTile(monthly, 0)}
          {getPriceTile(monthly, 1)}
          {getPriceTile(monthly, 2)}
          {waitlistMode ? getPriceTile(monthly, 3) : ""}
        </div>
      ) : (
        <Swiper
          modules={[Scrollbar]}
          scrollbar={{
            draggable: true,
            snapOnRelease: true,
            hide: false,
          }}
          spaceBetween={1}
          slidesPerView={1}
          centeredSlides={true}
          roundLengths={true}
        >
          {waitlistMode ? (
            <Fragment>
              <SwiperSlide>{getPriceTile(monthly, 3)}</SwiperSlide>
            </Fragment>
          ) : (
            <Fragment>
              <SwiperSlide>{getPriceTile(monthly, 0)}</SwiperSlide>
              <SwiperSlide>{getPriceTile(monthly, 1)}</SwiperSlide>
              <SwiperSlide>{getPriceTile(monthly, 2)}</SwiperSlide>
            </Fragment>
          )}
        </Swiper>
      )}
      {props && props.hideCircles ? null : (
        <span className="background-circles">
          <img src={circle1} className="circle1" />
          <img src={circle2} className="circle2" />
        </span>
      )}
    </div>
  );
};

export default withRouter(Prices);

import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import NavigationBar from "../Custom/NavigationBar";
import { Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import { useWindowDimensions } from "../Contexts/WindowDimension";
import boardChartImg from "../../images/boardChart.png";
import integrationImg from "../../images/integration.png";
import trackImg from "../../images/track.png";
import GetOnWaitlistElem from "./GetOnWaitlistElem";

const Features = (props) => {
  const [priceLookupKey, setPriceLookupKey] = useState(null);
  const [monthly, setMonthly] = useState(true);
  let history = useHistory();
  const { width, height } = useWindowDimensions();
  const isDesktop = width > 1024;
  const [waitlistMode, setWaitlistMode] = useState(false);
  const { paymentMethodsFetched, paymentMethods, activeCard, addToWaitlist } =
    useAuth();
  const [tile1idx, setTile1idx] = useState(0);
  const [tile2idx, setTile2idx] = useState(1);
  const [tile3idx, setTile3idx] = useState(2);

  const getPriceTile = (monthly, idx) => {
    switch (idx) {
      case 0:
        return (
          <div className="price-tile">
            <div className="vertical-container" style={{ height: "90%" }}>
              <div
                className="center"
                style={{
                  height: "73%",
                }}
              >
                <img
                  style={{
                    width: "192px",
                  }}
                  src={trackImg}
                />
              </div>
              <div
                style={{
                  marginTop: "auto",
                  marginBottom: "45px",
                }}
              >
                Track all your SaaS feedback in one place
              </div>
              <div style={{ cursor: "pointer" }} onClick={() => setTile1idx(3)}>
                <u>What does this mean?</u>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="price-tile">
            <div className="vertical-container" style={{ height: "90%" }}>
              <div
                className="center"
                style={{
                  height: "73%",
                }}
              >
                <img
                  style={{
                    width: "206px",
                  }}
                  src={boardChartImg}
                />
              </div>
              <div
                style={{
                  marginTop: "auto",
                  marginBottom: "24px",
                }}
              >
                Automatically update FAQs, Testimonials, Product roadmap on your
                website
              </div>
              <div style={{ cursor: "pointer" }} onClick={() => setTile2idx(4)}>
                <u>What does this mean?</u>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="price-tile">
            <div className="vertical-container" style={{ height: "90%" }}>
              <div
                className="center"
                style={{
                  height: "73%",
                }}
              >
                <img
                  style={{
                    width: "164px",
                  }}
                  src={integrationImg}
                />
              </div>
              <div
                style={{
                  marginTop: "auto",
                  marginBottom: "24px",
                }}
              >
                Easy and customisable integration with your SaaS
              </div>
              <div style={{ cursor: "pointer" }} onClick={() => setTile3idx(5)}>
                <u>What does this mean?</u>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="price-tile">
            <div className="vertical-container" style={{ height: "90%" }}>
              <div
                style={{
                  width: "234px",
                  height: "105px",
                  whiteSpace: "pre-wrap",
                }}
              >
                {
                  "There are several types of feedback that one may get from users.\nWe collect them and segregate them in a logical manner so you can action of the feedback for efficiently"
                }
              </div>
              <div style={{ cursor: "pointer" }} onClick={() => setTile1idx(0)}>
                <u>Undestood</u>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="price-tile">
            <div className="vertical-container" style={{ height: "90%" }}>
              <div
                style={{
                  width: "234px",
                  height: "231px",
                  whiteSpace: "pre-wrap",
                }}
              >
                {
                  "Testimonials help build trust in softwares, but creating them separately can be a hassle.\nWe automatically create testimonial section for your website\n\nProduct roadmaps help in user retention as there are features that they can look forward to.\nWe automatically create a product roadmap page for your website."
                }
              </div>
              <div style={{ cursor: "pointer" }} onClick={() => setTile2idx(1)}>
                <u>Undestood</u>
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="price-tile">
            <div className="vertical-container" style={{ height: "90%" }}>
              <div
                style={{
                  width: "239px",
                  height: "231px",
                  whiteSpace: "pre-wrap",
                }}
              >
                {
                  "Our tool integration requires minimal developer knowledge and understanding of web development and APIs.\n\nYou can customise the tool according to your requirement for a more personalised experience for your users."
                }
              </div>
              <div style={{ cursor: "pointer" }} onClick={() => setTile3idx(2)}>
                <u>Undestood</u>
              </div>
            </div>
          </div>
        );
      default:
        return "";
    }
  };

  const getAnimatedtile = (idx) => {
    // hide other price-tiles
    if (isDesktop) {
      for (let i = 0; i < 3; i++) {
        if (i !== idx) {
          document
            .getElementsByClassName("price-tile")
            [i].classList.add("fadeOut");
        }
      }
      setTimeout(() => {
        for (let i = 0; i < 3; i++) {
          if (i !== idx) {
            document.getElementsByClassName("price-tile")[i].style.display =
              "none";
          }
        }
      }, 500);
    } else {
      setWaitlistMode(true);
      return;
    }
    document
      .getElementsByClassName("coming-soon-triangle")
      [idx - 1].classList.add("coming-soon-whiteBg");
    document
      .getElementsByClassName("coming-soon-title")
      [idx - 1].classList.add("coming-soon-title-whiteBg");
    document
      .getElementsByClassName("price-tile")
      [idx].classList.add("price-tile-custom");
    document.getElementsByClassName("waitlist-btn-wrapper")[idx - 1].innerHTML =
      "Select an option on the right";
    setTimeout(() => {
      setWaitlistMode(true);
    }, 700);
  };

  if (priceLookupKey) {
    if (!paymentMethodsFetched) {
      // Toast message error
      history.push("/");
    }
    if (paymentMethods.length === 0 && !activeCard) {
      history.push("/add-card", { toSubscribe: true, priceId: priceLookupKey });
    } else {
      history.push("/subscribe", { priceId: priceLookupKey });
    }
  }

  const changePage = (page) => {
    document
      .getElementById("featuresPagination")
      .childNodes.forEach((child, idx) => {
        if (page === idx) child.classList.add("selected-page");
        else child.classList.remove("selected-page");
      });
  };
  return (
    <div>
      <NavigationBar hideLogin hidePricing />
      <div className="pricing-main-wrapper">
        <div className="pricing-main-title">Features</div>
      </div>
      {isDesktop ? (
        <div className="price-list">
          {getPriceTile(monthly, tile1idx)}
          {getPriceTile(monthly, tile2idx)}
          {getPriceTile(monthly, tile3idx)}
          {waitlistMode ? getPriceTile(monthly, 3) : ""}
        </div>
      ) : (
        <Fragment>
          <Swiper
            modules={[Scrollbar]}
            scrollbar={{
              draggable: true,
              snapOnRelease: true,
              hide: false,
            }}
            spaceBetween={1}
            slidesPerView={1}
            centeredSlides={true}
            roundLengths={true}
            onSlideChange={(idx) => changePage(idx.realIndex)}
          >
            <SwiperSlide>{getPriceTile(monthly, tile1idx)}</SwiperSlide>
            <SwiperSlide>{getPriceTile(monthly, tile2idx)}</SwiperSlide>
            <SwiperSlide>{getPriceTile(monthly, tile3idx)}</SwiperSlide>
          </Swiper>
          <div
            id="featuresPagination"
            className="horizontal-container"
            style={{
              justifyContent: "center",
              marginTop: "8px",
            }}
          >
            <div className="page-normal selected-page"></div>
            <div className="page-normal"></div>
            <div className="page-normal"></div>
          </div>
        </Fragment>
      )}
      <GetOnWaitlistElem uid="features" />
    </div>
  );
};

export default withRouter(Features);

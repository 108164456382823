import React, { useEffect } from "react";
import { useAuth } from "../Contexts/AuthContext";
import qs from "qs";

function GithubOauth(props) {
  const githubCode = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  }).code;
  const { getGithubAccessToken } = useAuth();

  useEffect(() => {
    getGithubAccessToken(githubCode);
    // getGithubUser("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");
  }, []);

  return <div>Hello : {githubCode}</div>;
}

GithubOauth.propTypes = {};

export default GithubOauth;

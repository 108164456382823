import React, { useState, useLayoutEffect, Fragment } from "react";
import "firebase/auth";
import googleIcon from "../../images/google-icon.svg";
import { Redirect } from "react-router-dom";
import hidden from "../../images/hidden.svg";
import visible from "../../images/visible.svg";
import { useAuth } from "../Contexts/AuthContext";
import NavigationBar from "../Custom/NavigationBar";
import Lottie from "react-lottie-player";
import loginJson from "../../images/loginJson.json";
import registerJson from "../../images/registerJson.json";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function LoginPage({ location }) {
  const { currentUser, login, signup, subscribed, loginWithGoogle } = useAuth();
  const [width, height] = useWindowSize();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [register, setRegister] = useState(
    location && location.state && location.state.fromFreeUpgrade ? true : false
  );
  const [showPassword, setShowPassword] = useState(false);
  const defaultInputBorder = "#466EF9";
  const isMobile = width < 1024;
  const redirectHome = () => {
    return <Redirect to="/" />;
  };

  if (currentUser != null && subscribed == 1) {
    return <Redirect to="/dashboard" />;
  }

  if (currentUser != null) {
    return <Redirect to="/" />;
  }

  const resetErrors = (field) => {
    document.getElementById(field + "-error").innerHTML = "";
    document.getElementById(field + "-error").style.display = "nonde";
    document.getElementById(field).style.borderColor = defaultInputBorder;
  };

  return (
    <div>
      <NavigationBar hideLogin />
      <div
        className="login-page-wrapper"
        style={
          isMobile
            ? {}
            : {
                width: "1009px",
                height: "440px",
                marginLeft: "auto",
                marginRight: "auto",
              }
        }
      >
        <div className="login-image-wrapper">
          <Lottie
            loop
            animationData={register ? registerJson : loginJson}
            play
            style={{
              height: "310px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          <div className="login-image-text">
            {register
              ? "We are waiting for you to\nget to  your dashboard"
              : "Your journey towards better customer\nengament begins today. Mark the date:)"}
          </div>
        </div>
        <div className="login-register-wrapper">
          <div className="login-form-title">
            {register ? "Create your account" : "Log in to your account"}
          </div>
          <form
            id="myForm"
            className="login-form-wrapper"
            onSubmit={(e) => {
              e.preventDefault();
              login(email, password);
            }}
          >
            <label htmlFor="email">Email &emsp;</label>
            <input
              type="text"
              id="email"
              name="email"
              value={email}
              onChange={(e) => {
                resetErrors("email");
                setEmail(e.target.value);
              }}
            />
            <span id="email-error" className="field-errors"></span>
            <label htmlFor="password">Password &emsp;</label>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={(e) => {
                  resetErrors("password");
                  setPassword(e.target.value);
                }}
                style={{ marginLeft: "-10px" }}
              />
              <img
                onClick={() => setShowPassword(!showPassword)}
                src={showPassword ? visible : hidden}
                id="password-icon"
              />
            </div>
            <span id="password-error" className="field-errors"></span>
          </form>

          {isMobile ? (
            <div
              className="login-toggle-register"
              onClick={() => setRegister(!register)}
            >
              {register
                ? "Already have an account ?"
                : "Don’t have an account?"}
            </div>
          ) : (
            ""
          )}
          <div className="login-button-wrapper">
            <div className="submit-button">
              <input
                type="submit"
                form="myForm"
                value={register ? "Register" : "Login"}
              />
            </div>
            <div className="googleLoginButtonWrapper">
              <button onClick={() => loginWithGoogle()}>
                <img src={googleIcon} />
                Sign in with Google
              </button>
            </div>
          </div>
          {isMobile ? (
            ""
          ) : (
            <div
              className="login-toggle-register"
              onClick={() => setRegister(!register)}
            >
              {register
                ? "Already have an account ?"
                : "Don’t have an account?"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;

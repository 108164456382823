import "../stylesheets/App.css";
import "../stylesheets/Billing.css";
import "../stylesheets/feedbackDashboard.css";
import "../stylesheets/feedbackItem.css";
import "../stylesheets/feedbackSettings.css";
import { Fragment } from "react";
import FeedbackDashboardWrapper from "./Dashoard/feedbackDashboard";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import Account from "./Billing/Account";
import SignOut from "./LoginAuthentication/signOut";
import Prices from "./Billing/Prices";
import AddCards from "./Billing/AddCards";
import LandingPage from "./LandingPage/landingPage";
import LoginPage from "./LoginAuthentication/loginPage";
import GithubOauth from "./LoginAuthentication/githubOauth";
import { AuthProvider } from "./Contexts/AuthContext";
import CustomRoute from "../components/Custom/CustomRoute";
import WaitlistForm from "./LandingPage/waitlistForm";
import SubscribtionPage from "../components/Billing/SubscribtionPage";
import FeedbackSettings from "../components/DashboardSettings/FeedbackSettings";
import "feedbackplace-react/style/index.css";

function App() {
  return (
    <Fragment>
      <div>
        <header className="App-header">
          <AuthProvider>
            <Router history={useHistory()}>
              <CustomRoute path="/login" component={LoginPage} />
              <CustomRoute path="/" exact component={LandingPage} />
              <CustomRoute path="/waitlist" component={WaitlistForm} />
              {/* <CustomRoute
                path="/dashboard"
                component={FeedbackDashboardWrapper}
                private
              />
              <CustomRoute
                path="/settings"
                component={FeedbackSettings}
                private
              />
              <CustomRoute path="/prices" component={Prices} />
              <CustomRoute
                path="/subscribe"
                component={SubscribtionPage}
                private
                black
              />
              <CustomRoute
                path="/add-card"
                component={AddCards}
                private
                black
              />
              <CustomRoute path="/cards" component={Account} private black />
              <CustomRoute path="/signout" component={SignOut} private />
              <CustomRoute
                path="/oauth/github"
                component={GithubOauth}
                private
              /> */}
            </Router>
          </AuthProvider>
        </header>
      </div>
    </Fragment>
  );
}

export default App;

import React, { useState, useLayoutEffect, Fragment } from "react";
import "firebase/auth";
import circle1 from "../../images/circle1.svg";
import circle2 from "../../images/circle2.svg";
import { Redirect } from "react-router-dom";
import img from "../../images/pageImg.png";
import { useAuth } from "../Contexts/AuthContext";
import NavigationBar from "../Custom/NavigationBar";

function WaitlistForm() {
  const { currentUser, signup, subscribed, addToWaitlist } = useAuth();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const defaultInputBorder = "#466EF9";

  const redirectHome = () => {
    return <Redirect to="/" />;
  };

  if (currentUser != null && subscribed == 1) {
    return <Redirect to="/dashboard" />;
  }

  if (currentUser != null) {
    return <Redirect to="/" />;
  }

  const resetErrors = (field) => {
    document.getElementById(field + "-error").innerHTML = "";
    document.getElementById(field + "-error").style.display = "nonde";
    document.getElementById(field).style.borderColor = defaultInputBorder;
  };

  return (
    <div>
      <NavigationBar hideLogin />
      <div className="login-page-wrapper">
        <div className="login-image-wrapper">
          <img src={img} />
        </div>
        <div className="login-register-wrapper">
          <div className="login-form-title">
            We will send you an email to notify you. No Spam.
          </div>
          <form
            id="myForm"
            className="login-form-wrapper"
            onSubmit={async (e) => {
              e.preventDefault();
              await addToWaitlist(fullName, email, location);
            }}
          >
            <label htmlFor="fullName">Full Name &emsp;</label>
            <input
              type="text"
              id="fname"
              name="fullName"
              value={fullName}
              onChange={(e) => {
                resetErrors("fname");
                setFullName(e.target.value);
              }}
            />
            <span id="fname-error" className="field-errors"></span>

            <label htmlFor="email">Email &emsp;</label>
            <input
              type="text"
              id="email"
              name="email"
              value={email}
              onChange={(e) => {
                resetErrors("email");
                setEmail(e.target.value);
              }}
            />
            <span id="email-error" className="field-errors"></span>

            <label htmlFor="location">Location &emsp;</label>
            <input
              type="text"
              id="location"
              name="location"
              value={location}
              onChange={(e) => {
                resetErrors("location");
                setLocation(e.target.value);
              }}
            />
            <span id="location-error" className="field-errors"></span>
          </form>
          <div className="login-button-wrapper" style={{ display: "block" }}>
            <div className="submit-button" style={{ width: "100%" }}>
              <input type="submit" form="myForm" value="Add me to waitlist" />
            </div>
          </div>

          <span className="background-circles">
            <img src={circle1} className="circle1" />
            <img src={circle2} className="circle2" />
          </span>
        </div>
      </div>
    </div>
  );
}

export default WaitlistForm;

import React, { useState, useEffect, Fragment } from "react";
import Lottie from "react-lottie-player";
import FeedbackItemCard, { FeedbackHeaderItemCard } from "./feedbackItem";
import InfiniteScroll from "react-infinite-scroll-component";
import FadeIn from "react-fade-in";
import { Redirect, Link } from "react-router-dom";
import history from "../Other/history";
import Expand from "react-expand-animated";
import { useAuth } from "../Contexts/AuthContext";
import { useWindowDimensions } from "../Contexts/WindowDimension";
import lottieJson from "../../images/loadingBlack.json";
import docImg from "../../images/document.svg";
import addImg from "../../images/add.svg";
import sortImg from "../../images/sort.svg";
import closeImg from "../../images/cross-black.svg";
import settingImg from "../../images/settingIcon.svg";
import CustomDropdown from "../Custom/CustomDropdown";
import Cookies from "js-cookie";

function FeedbackDashboardWrapper() {
  //region variables
  const [loadingText, setLoadingText] = useState("Looking for results...");
  const [loadingAnim, setLoadingAnim] = useState(true);
  const [screenMode, setScreenMode] = useState("normal");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortQuery, setSortQuery] = useState("");
  const [viewArchived, setViewArchived] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const updatePageData = (data) => {
    if (!data) {
      setLoadingText("No results found");
      setPageData([]);
      return;
    }
    if (data.length === 0) {
      setLoadingText("No results found");
    } else {
      setLoadingText("Looking for results...");
    }
    setPageData(data);
  };
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menuVisible, setMenuVisible] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [filterMode, setFilterMode] = useState(0);
  const [currentList, setCurrentList] = useState("");
  const [resultsMode, setResultsMode] = useState(0);
  const [feedbackTypeSelected, setFeedbackTypeSelected] = useState("");
  const [feedbackPrioritySelected, setFeedbackPrioritySelected] = useState("");
  const [feedbackFolderSelected, setFeedbackFolderSelected] = useState("");

  const {
    logout,
    currentUserData,
    apiCallUrl,
    setModalShow,
    setModalTitle,
    setModalBody,
    setModalNegativeBtn,
    setModalPositiveBtn,
    setModalPositiveBtnClick,
    addToastMessage,
    localAuthTokenSave,
  } = useAuth();
  const { width, height } = useWindowDimensions();

  const [selectedFeedbackFolder, setSelectedFeedbackFolder] = useState("***");
  const [feedbackTypes, setFeedbackTypes] = useState([]);
  const [priorityTypes, setPriorityTypes] = useState([]);
  const [fetchedFolders, setFetchedFolders] = useState([]);
  const resultCount = 8;
  const sortValues = [
    { label: "Oldest first", value: "dateOldest" },
    { label: "Latest first", value: "dateLatest" },
    { label: "Higher priority first", value: "priorityHighest" },
    { label: "Lower priority first", value: "priorityLowest" },
  ];
  let archivedFeedbacks = new Set();

  //endregion
  // --------------------------------------------------------------------------------------------------------

  useEffect(async () => {
    document.body.classList.add("body-light-bg");
    await getPriorityTypes();
    await getFeedbackTypes();
    await getFoldersTypes();
  }, []);

  useEffect(() => {
    if (selectedFeedbackFolder === "***") {
      getAllFeedbackData(currentPage);
    } else {
      // get selected folders feedbacks
    }
  }, [
    selectedFeedbackFolder,
    currentPage,
    feedbackPrioritySelected,
    feedbackTypeSelected,
    feedbackFolderSelected,
    searchQuery,
    sortQuery,
    viewArchived,
  ]);

  useEffect(() => {
    if (width < 1220) {
      setScreenMode("mobile");
    } else {
      setScreenMode("normal");
    }
  }, [width]);

  const serialize = (obj) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };
  const getAllFeedbackData = async (page) => {
    setLoadingAnim(true);
    await fetch(
      apiCallUrl +
        "/feedbacks?" +
        serialize({
          count: resultCount,
          index: page,
          priority: feedbackPrioritySelected,
          feedbackType: feedbackTypeSelected,
          folderId: feedbackFolderSelected,
          sortType: sortQuery,
          searchQuery: searchQuery,
          archived: viewArchived,
        }),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + Cookies.get(localAuthTokenSave),
        },
      }
    )
      .then((r) => r.json())
      .then((res) => {
        console.log("Data", res);
        if ("data" in res) {
          updatePageData(res["data"]);
        } else {
          updatePageData([]);
        }
        if ("pages" in res) {
          setPagesCount(res["pages"]);
        }
      });
  };

  const getFeedbackTypes = async () => {
    await fetch(apiCallUrl + "/feedbacks/feedbackTypes", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + Cookies.get(localAuthTokenSave),
      },
    })
      .then((r) => r.json())
      .then((data) => {
        console.log("TypeData", data);
        setFeedbackTypes(data);
      });
  };

  const getPriorityTypes = async () => {
    await fetch(apiCallUrl + "/feedbacks/priorityTypes", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + Cookies.get(localAuthTokenSave),
      },
    })
      .then((r) => r.json())
      .then((data) => {
        console.log("PriorityData", data);
        setPriorityTypes(data);
      });
  };

  const getFoldersTypes = async () => {
    await fetch(apiCallUrl + "/folders", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + Cookies.get(localAuthTokenSave),
      },
    })
      .then((r) => r.json())
      .then((data) => {
        setFetchedFolders(data);
      });
  };

  const addFolder = async (name) => {
    await fetch(apiCallUrl + "/folders/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + Cookies.get(localAuthTokenSave),
      },
      body: JSON.stringify({ label: name }),
    })
      .then((r) => r.json())
      .then((data) => {
        setModalShow(false);
        getFoldersTypes();
        addToastMessage("Successfully added folder", "success");
      });
  };

  const addFeedbackToFolder = async (feedbackId, folderId) => {
    await fetch(apiCallUrl + "/folders/addFeedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + Cookies.get(localAuthTokenSave),
      },
      body: JSON.stringify({ feedbackId: feedbackId, folderId: folderId }),
    })
      .then((r) => r.json())
      .then((data) => {
        setModalShow(false);
        addToastMessage("Added feedback successfully", "success");
      });
  };

  const removeFeedbackFromFolder = async (feedbackId, folderId) => {
    await fetch(apiCallUrl + "/folders/removeFeedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + Cookies.get(localAuthTokenSave),
      },
      body: JSON.stringify({ feedbackId: feedbackId, folderId: folderId }),
    })
      .then((r) => r.json())
      .then((data) => {
        setFetchedFolders(data);
      });
  };

  const showModalForAddFeedbackToFolder = (feedbackId) => {
    const modalBody = (
      <Fragment>
        <div className="vertical-wrapper">
          {fetchedFolders.map((elem) => (
            <div
              id={elem["_id"]}
              key={elem["_id"]}
              className="dark-button-type-wrapper light-button-theme folder-type"
              style={{ marginRight: "0", marginTop: "4px" }}
              onClick={() => {
                setModalShow(false);
                addFeedbackToFolder(feedbackId, elem["_id"]);
              }}
            >
              {elem["label"]}
            </div>
          ))}
        </div>
      </Fragment>
    );
    setModalTitle("Select folder");
    setModalPositiveBtn("");
    setModalNegativeBtn("Close");
    setModalBody(modalBody);
    setModalShow(true);
  };

  const archiveFeedback = async (feedbackId) => {
    const currentDate = new Date();
    const numberOfDaysToAdd = 6;
    const result = currentDate.setDate(
      currentDate.getDate() + numberOfDaysToAdd
    );
    await fetch(apiCallUrl + "/feedbacks/" + feedbackId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + Cookies.get(localAuthTokenSave),
      },
      body: JSON.stringify({ expireAt: new Date(result), archived: true }),
    })
      .then((r) => {
        if (r.status === 200) {
          return r.json();
        } else {
          addToastMessage("Something went wrong", "warning");
        }
      })
      .then((res) => {
        console.log(res);
        addToastMessage("Feedback archived successfully", "success");
        archivedFeedbacks.add(feedbackId);
      })
      .catch((e) => addToastMessage("Feedback not archived", "error"));
  };

  const showModalForAddFolder = () => {
    const modalBody = (
      <Fragment>
        <div className="vertical-wrapper transparent-button">
          <input
            id="add-folder-input-text"
            className="transparent-input"
            style={{ width: "100%", padding: "0 16px" }}
            placeholder="Enter name for folder"
            autoFocus
          />
        </div>
      </Fragment>
    );
    setModalTitle("Enter new folder name");
    setModalPositiveBtn("Add");
    setModalNegativeBtn("Close");
    setModalPositiveBtnClick(() => () => {
      if (document.getElementById("add-folder-input-text"))
        addFolder(document.getElementById("add-folder-input-text").value);
    });
    setModalBody(modalBody);
    setModalShow(true);
  };

  const changeBtnSelectClass = (changeTo, className) => {
    var allFeedbackTypeElems = document.getElementsByClassName(className);
    for (let index = 0; index < allFeedbackTypeElems.length; index++) {
      const element = allFeedbackTypeElems[index];
      element.classList.remove("dark-button-type-selected");
    }
    document
      .getElementById(changeTo)
      .classList.add("dark-button-type-selected");
  };

  const searchWithKeyword = () => {
    if (searchKeyword.trim().length > 0) {
      setSearchQuery(searchKeyword.trim());
      setCurrentPage(1);
    }
  };

  const dataPage = () => {
    return pageData.map((feedbackData, idx) => (
      <FeedbackItemCard
        useWindow={false}
        id={feedbackData["_id"]}
        key={feedbackData["_id"]}
        feedbackText={feedbackData["text"]}
        feedbackType={getFeedbackTypeById(feedbackData["feedbackType"])}
        date={feedbackData["createdAt"]}
        priorityVal={feedbackData["priority"]}
        priorityTypes={priorityTypes}
        actionOptions={[
          {
            label: "add to folder",
            click: (id) => showModalForAddFeedbackToFolder(id),
          },
          {
            label: "archive",
            click: (id) => archiveFeedback(id),
            disableLater: true,
          },
        ]}
        defaultBgColor={idx % 2}
      />
    ));
  };

  const getPaginationContent = () => {
    var ret = [];
    for (let index = 0; index < pagesCount; index++) {
      ret.push(index + 1);
    }
    return ret.map((i) => (
      <div
        key={"pagination-" + i}
        className={currentPage === i ? "page-selected" : ""}
        onClick={() => {
          if (currentPage !== i) setCurrentPage(i);
        }}
      >
        {i}
      </div>
    ));
  };

  const nextPage = (last) => {
    if (last) {
      setCurrentPage(pagesCount);
    } else if (currentPage < pagesCount) {
      setCurrentPage(currentPage + 1);
    }
  };
  const previousPage = (first) => {
    if (first) {
      setCurrentPage(1);
    } else if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getFeedbackTypeById = (id) => {
    for (let index = 0; index < feedbackTypes.length; index++) {
      const element = feedbackTypes[index];
      if (element["_id"] === id) return element;
    }
    return null;
  };

  const searchEnterPress = (event) => {
    if (event.key === "Enter" && searchKeyword.trim().length > 0) {
      searchWithKeyword();
    }
  };

  const filterWrapper = (modeNumber, tagsArray, title, forMobile) => {
    return (
      <Fragment>
        <span
          className="filter-title"
          onClick={() =>
            filterMode === modeNumber
              ? setFilterMode(0)
              : setFilterMode(modeNumber)
          }
        >
          {title}
          <a
            className={
              filterMode === modeNumber ? "arrow-icon open" : "arrow-icon"
            }
          >
            <span className="left-bar"></span>
            <span className="right-bar"></span>
          </a>
        </span>

        <Expand
          styles={{ open: { height: "35%" } }}
          open={filterMode === modeNumber}
          transitions={["height", "opacity"]}
          duration={200}
        >
          <div className="filter-wrapper">
            <div className="filter-container">
              <ul>
                {tagsArray.map((tag, key) => (
                  <li key={key}>
                    <label htmlFor={title + tag.id}>
                      <input
                        style={{ marginRight: "5px" }}
                        type="checkbox"
                        id={title + tag.id}
                        name={tag.id}
                        value={tag.id}
                      />
                      {tag.value}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Expand>
      </Fragment>
    );
  };

  const restToAllFeedback = () => {
    setViewArchived(false);
    setFeedbackTypeSelected("");
    setFeedbackPrioritySelected("");
    setFeedbackFolderSelected("");
    setSearchQuery("");
    changeBtnSelectClass("all-feedback", "feedback-type");
    changeBtnSelectClass("all-feedback", "folder-type");
    changeBtnSelectClass("all-feedback", "priority-type");
  };

  // --------------------------------------------------------------------------------------------------------
  if (redirect) {
    console.log(redirect);
    history.push(redirect);
    return (
      <Redirect
        to={{
          pathname: redirect,
        }}
      />
    );
  }
  if (screenMode === "normal") {
    return (
      <div id="dashboard-root">
        <div
          className="top-bar-wrapper horizontal-wrapper body-light-bg"
          style={{ justifyContent: "space-between" }}
        >
          <div
            className="website-title"
            onClick={() => (window.location.href = "/")}
          >
            Feedback Place
          </div>

          <ul className="top-bar-links">
            <li>
              <Link
                to="/signout"
                style={{
                  color: "black",
                  textDecoration: "unset",
                  margin: "0",
                  padding: "0",
                }}
              >
                <i className="fa fa-sign-out"></i>
                <span
                  className="menu-options-title"
                  style={{ marginLeft: "4px" }}
                >
                  Signout
                </span>
              </Link>
            </li>
          </ul>
        </div>
        <div
          className="horizontal-wrapper"
          style={{
            marginTop: "120px",
            height: "80vh",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="left-sidebar-wrapper vertical-wrapper"
            style={{ marginTop: "1px" }}
          >
            <div
              id="all-feedback"
              className={
                "dark-button-type-wrapper light-button-theme feedback-type folder-type priority-type " +
                (feedbackTypeSelected === ""
                  ? " dark-button-type-selected"
                  : "")
              }
              style={{ marginRight: "0" }}
              onClick={() => {
                restToAllFeedback();
              }}
            >
              All Feedback
            </div>
            <div
              className="vertical-wrapper"
              style={{ marginTop: "16px", width: "100%" }}
            >
              <div className="side-bar-title">
                <div>Folders</div>
                {feedbackFolderSelected !== "" ? (
                  <div
                    className="clear-filter-wrapper"
                    onClick={() => {
                      setFeedbackFolderSelected("");
                      changeBtnSelectClass("all-feedback", "folder-type");
                    }}
                  >
                    <img
                      src={closeImg}
                      style={{ cursor: "pointer", marginRight: "7px" }}
                    />
                    <div>clear filters</div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                className="vertical-wrapper"
                style={{
                  maxHeight: "25vh",
                  overflowX: "hidden",
                  overflowY: "auto",
                  display: "block",
                  width: "100%",
                }}
              >
                {fetchedFolders.map((elem) => (
                  <div
                    id={elem["_id"]}
                    key={elem["_id"]}
                    className="dark-button-type-wrapper light-button-theme folder-type"
                    style={{ marginRight: "0", marginTop: "4px" }}
                    onClick={() => {
                      changeBtnSelectClass(elem["_id"], "folder-type");
                      setFeedbackFolderSelected(elem["_id"]);
                    }}
                  >
                    {elem["label"]}
                  </div>
                ))}
              </div>
              <div
                style={{
                  marginTop: "16px",
                  display: "flex",
                  justifyContent: "start",
                  cursor: "pointer",
                  width: "100%",
                }}
                onClick={() => showModalForAddFolder()}
              >
                <img
                  src={addImg}
                  style={{ marginRight: "45px", marginLeft: "18px" }}
                />
                Add Folder
              </div>
            </div>
            <div
              className="vertical-wrapper"
              style={{ marginTop: "40px", width: "100%" }}
            >
              <div className="side-bar-title">
                <div>Priority</div>
                {feedbackPrioritySelected !== "" ? (
                  <div
                    className="clear-filter-wrapper"
                    onClick={() => {
                      setFeedbackPrioritySelected("");
                      changeBtnSelectClass("all-feedback", "priority-type");
                    }}
                  >
                    <img
                      src={closeImg}
                      style={{ cursor: "pointer", marginRight: "7px" }}
                    />
                    <div>clear filters</div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {priorityTypes.map((elem) => (
                <div
                  id={elem["_id"]}
                  key={elem["_id"]}
                  className="dark-button-type-wrapper light-button-theme priority-type"
                  style={{ marginRight: "0", marginTop: "4px" }}
                  onClick={() => {
                    changeBtnSelectClass(elem["_id"], "priority-type");
                    setFeedbackPrioritySelected(elem["_id"]);
                  }}
                >
                  {elem["label"]}
                </div>
              ))}
            </div>
            <div
              className="dark-button-type-wrapper light-button-theme"
              style={{
                marginTop: "auto",
                justifyContent: "start",
              }}
            >
              <img
                src={docImg}
                style={{ marginRight: "45px", marginLeft: "18px" }}
              />
              Documentation
            </div>
          </div>
          <div className="right-side-wrapper vertical-wrapper">
            <div
              className="horizontal-wrapper"
              style={{
                width: "calc(100% - 16px)",
                margin: "0 8px",
                justifyContent: "start",
                overflowX: "auto",
                paddingBottom: "8px",
              }}
            >
              {feedbackTypes.map((elem, key) => {
                return (
                  <div
                    className="dark-button-type-wrapper light-button-theme feedback-type"
                    id={elem["_id"]}
                    key={elem["_id"]}
                    onClick={() => {
                      changeBtnSelectClass(elem["_id"], "feedback-type");
                      setFeedbackTypeSelected(elem["_id"]);
                    }}
                    style={
                      key === feedbackTypes.length - 1
                        ? {}
                        : { marginRight: "16px" }
                    }
                  >
                    {elem["name"]}
                  </div>
                );
              })}
            </div>
            <div
              className="horizontal-wrapper"
              style={{
                marginTop: "4px",
                justifyContent: "space-between",
                width: "100%",
                padding: "0 10px",
              }}
            >
              <div
                className="horizontal-wrapper"
                style={{ width: "170px", justifyContent: "space-between" }}
              >
                <CustomDropdown
                  listItems={sortValues}
                  defaultItem={
                    <div>
                      <img
                        src={sortImg}
                        style={{
                          marginRight: "9px",
                          width: "9.29px",
                          height: "10px",
                          borderRadius: "4px",
                        }}
                      />
                      Sort
                    </div>
                  }
                  uid={"sortBtn"}
                  selectBg={{
                    backgroundColor: "white",
                    padding: "8px",
                    color: "black",
                  }}
                  initSelectedItem={sortQuery === "" ? null : sortQuery}
                  onItemSelected={(val) => {
                    setSortQuery(val);
                  }}
                />
                {sortQuery === "" ? (
                  ""
                ) : (
                  <div
                    className="clear-filter-wrapper"
                    onClick={() => {
                      setSortQuery("");
                    }}
                  >
                    <img
                      src={closeImg}
                      style={{ cursor: "pointer", marginRight: "7px" }}
                    />
                    <div>clear filters</div>
                  </div>
                )}
              </div>

              <div
                style={{
                  width: "24px",
                  height: "24px",
                  background: "#FFFFFF",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "auto",
                  marginRight: "14px",
                  cursor: "pointer",
                }}
                onClick={() => (window.location.href = "/settings")}
              >
                <img src={settingImg} />
              </div>

              <input
                id="searchBar"
                type="text"
                value={searchKeyword}
                placeholder="Search.."
                className={
                  searchKeyword.length > 0
                    ? "search-input search-icon-right"
                    : "search-input search-icon-left"
                }
                onChange={(e) => setSearchKeyword(e.target.value)}
                onKeyPress={searchEnterPress}
              />
            </div>
            <FadeIn className="w-100">
              {pageData.length === 0 || loadingAnim ? (
                <div
                  className="no-results"
                  style={{
                    height: "65vh",
                    alignItems: "center",
                  }}
                >
                  <center>
                    {loadingText === "Looking for results..." ? (
                      <Fragment>
                        <Lottie
                          loop
                          animationData={lottieJson}
                          play
                          onLoopComplete={() => {
                            console.log("animation complete");
                            if (pageData.length !== 0) {
                              setLoadingAnim(false);
                            }
                          }}
                          style={{ width: 150, height: 150 }}
                        />
                        <span
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          Gathering the data
                        </span>
                      </Fragment>
                    ) : currentList ? (
                      loadingText + " in " + currentList
                    ) : (
                      loadingText
                    )}
                  </center>
                </div>
              ) : (
                <div className="card-wrapper" style={{ height: "63vh" }}>
                  <FeedbackHeaderItemCard />
                  {dataPage()}
                </div>
              )}
            </FadeIn>
            <div
              style={{
                marginLeft: "8px",
                marginRight: "8px",
                height: "40px",
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                borderRadius: "0px 0px 4px 4px",
                width: "-webkit-fill-available",
              }}
            >
              <div
                className="archive-text"
                onClick={() => {
                  restToAllFeedback();
                  setViewArchived(true);
                }}
              >
                view archived
              </div>
              <div className="pagination-wrapper">
                <div onClick={() => previousPage(true)}>
                  <i className="arrow left" />
                  <i className="arrow left" />
                </div>
                <div onClick={() => previousPage(false)}>
                  <i className="arrow left" />
                </div>
                {getPaginationContent()}
                <div onClick={() => nextPage(false)}>
                  <i className="arrow right" />
                </div>
                <div onClick={() => nextPage(true)}>
                  <i className="arrow right" />
                  <i className="arrow right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="dashboard-root">
        <div className="top-bar-wrapper">
          <span
            className="website-title"
            onClick={() => (window.location.href = "/")}
          >
            Feedback place
          </span>
          <span
            onClick={() => setMenuVisible(!menuVisible)}
            className="menu-button"
          >
            <i className="fa fa-bars" />
          </span>
        </div>
        <div className="mobile-bottom-background">
          <div className="search-wrapper">
            <input
              id="searchBar"
              type="text"
              value={searchKeyword}
              placeholder="Search.."
              className={
                searchKeyword.length > 0
                  ? "search-input search-icon-right"
                  : "search-input search-icon-left"
              }
              onChange={(e) => setSearchKeyword(e.target.value)}
              onKeyPress={searchEnterPress}
            ></input>
          </div>
        </div>

        <FadeIn>
          {pageData.length === 0 || loadingAnim ? (
            <div
              className="no-results"
              style={{
                height: "65vh",
                alignItems: "center",
              }}
            >
              <center>
                {loadingText === "Looking for results..." ? (
                  <Fragment>
                    <Lottie
                      loop
                      animationData={lottieJson}
                      play
                      onLoopComplete={() => {
                        console.log("animation complete");
                        if (pageData.length !== 0) {
                          setLoadingAnim(false);
                        }
                      }}
                      style={{ width: 150, height: 150 }}
                    />
                    <span
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      Gathering the data
                    </span>
                  </Fragment>
                ) : currentList ? (
                  loadingText + " in " + currentList
                ) : (
                  loadingText
                )}
              </center>
            </div>
          ) : (
            <InfiniteScroll
              dataLength={pageData.length}
              next={() => nextPage()}
              hasMore={true}
              className="card-wrapper"
            >
              <FeedbackHeaderItemCard />
              {dataPage()}
            </InfiniteScroll>
          )}
        </FadeIn>
      </div>
    );
  }
}

export default FeedbackDashboardWrapper;

import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import NavigationBar from "../Custom/NavigationBar";
import { Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import { useWindowDimensions } from "../Contexts/WindowDimension";
import rocketImg from "../../images/rocket.png";
import computerStatsImg from "../../images/computerStats.png";
import userRetentionImg from "../../images/userRetention.png";
import GetOnWaitlistElem from "./GetOnWaitlistElem";

const CompanyBenefits = (props) => {
  const [priceLookupKey, setPriceLookupKey] = useState(null);
  const [monthly, setMonthly] = useState(true);
  let history = useHistory();
  const { width, height } = useWindowDimensions();
  const isDesktop = width > 1024;
  const [waitlistMode, setWaitlistMode] = useState(false);
  const { paymentMethodsFetched, paymentMethods, activeCard } = useAuth();

  const getPriceTile = (monthly, idx) => {
    switch (idx) {
      case 0:
        return (
          <div className="price-tile">
            <div className="vertical-container" style={{ height: "90%" }}>
              <div
                className="center"
                style={{
                  height: "73%",
                }}
              >
                <img
                  style={{
                    width: "190px",
                  }}
                  src={userRetentionImg}
                />
              </div>
              <div
                style={{
                  marginTop: "auto",
                  marginBottom: "24px",
                }}
              >
                Keeping your customers engaged helps in better user retention.
              </div>
              <div>
                <div style={{ fontWeight: "600" }}>Alternative:</div> <br></br>
                <font color="#FF9292">
                  Individually reach out to your customer via email, which is
                  inefficient.
                </font>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="price-tile">
            <div className="vertical-container" style={{ height: "90%" }}>
              <div
                className="center"
                style={{
                  height: "73%",
                }}
              >
                <img
                  style={{
                    width: "120px",
                  }}
                  src={rocketImg}
                />
              </div>
              <div
                style={{
                  marginTop: "auto",
                  marginBottom: "24px",
                }}
              >
                Higher quality feedback will help you develop your product
                better.
              </div>
              <div>
                <div style={{ fontWeight: "600" }}>Alternative:</div> <br></br>
                <font color="#FF9292">
                  User research at a small scale, which requires more time and
                  effort.
                </font>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="price-tile">
            <div className="vertical-container" style={{ height: "90%" }}>
              <div
                className="center"
                style={{
                  height: "73%",
                }}
              >
                <img
                  style={{
                    width: "256px",
                  }}
                  src={computerStatsImg}
                />
              </div>
              <div
                style={{
                  marginTop: "auto",
                  marginBottom: "24px",
                }}
              >
                Automatically update your website information using our API.
              </div>
              <div>
                <div style={{ fontWeight: "600" }}>Alternative:</div>
                <br></br>
                <font color="#FF9292">
                  Manually update information, which takes up developer’s time
                </font>
              </div>
            </div>
          </div>
        );
      case 3:
        return <div className="price-tile"></div>;
      default:
        return "";
    }
  };

  const getAnimatedtile = (idx) => {
    // hide other price-tiles
    if (isDesktop) {
      for (let i = 0; i < 3; i++) {
        if (i !== idx) {
          document
            .getElementsByClassName("price-tile")
            [i].classList.add("fadeOut");
        }
      }
      setTimeout(() => {
        for (let i = 0; i < 3; i++) {
          if (i !== idx) {
            document.getElementsByClassName("price-tile")[i].style.display =
              "none";
          }
        }
      }, 500);
    } else {
      setWaitlistMode(true);
      return;
    }
    document
      .getElementsByClassName("coming-soon-triangle")
      [idx - 1].classList.add("coming-soon-whiteBg");
    document
      .getElementsByClassName("coming-soon-title")
      [idx - 1].classList.add("coming-soon-title-whiteBg");
    document
      .getElementsByClassName("price-tile")
      [idx].classList.add("price-tile-custom");
    document.getElementsByClassName("waitlist-btn-wrapper")[idx - 1].innerHTML =
      "Select an option on the right";
    setTimeout(() => {
      setWaitlistMode(true);
    }, 700);
  };

  if (priceLookupKey) {
    if (!paymentMethodsFetched) {
      // Toast message error
      history.push("/");
    }
    if (paymentMethods.length === 0 && !activeCard) {
      history.push("/add-card", { toSubscribe: true, priceId: priceLookupKey });
    } else {
      history.push("/subscribe", { priceId: priceLookupKey });
    }
  }

  const changePage = (page) => {
    document
      .getElementById("benefitsPagination")
      .childNodes.forEach((child, idx) => {
        if (page === idx) child.classList.add("selected-page");
        else child.classList.remove("selected-page");
      });
  };

  return (
    <div>
      <NavigationBar hideLogin hidePricing />
      <div className="pricing-main-wrapper">
        <div className="pricing-main-title">Benefits for your company</div>
      </div>
      {isDesktop ? (
        <div className="price-list">
          {getPriceTile(monthly, 0)}
          {getPriceTile(monthly, 1)}
          {getPriceTile(monthly, 2)}
          {waitlistMode ? getPriceTile(monthly, 3) : ""}
        </div>
      ) : (
        <Fragment>
          <Swiper
            modules={[Scrollbar]}
            scrollbar={{
              draggable: true,
              snapOnRelease: true,
              hide: false,
            }}
            spaceBetween={1}
            slidesPerView={1}
            centeredSlides={true}
            roundLengths={true}
            onSlideChange={(idx) => changePage(idx.realIndex)}
          >
            {waitlistMode ? (
              <Fragment>
                <SwiperSlide>{getPriceTile(monthly, 3)}</SwiperSlide>
              </Fragment>
            ) : (
              <Fragment>
                <SwiperSlide>{getPriceTile(monthly, 0)}</SwiperSlide>
                <SwiperSlide>{getPriceTile(monthly, 1)}</SwiperSlide>
                <SwiperSlide>{getPriceTile(monthly, 2)}</SwiperSlide>
              </Fragment>
            )}
          </Swiper>
          <div
            id="benefitsPagination"
            className="horizontal-container"
            style={{
              justifyContent: "center",
              marginTop: "8px",
            }}
          >
            <div className="page-normal selected-page"></div>
            <div className="page-normal"></div>
            <div className="page-normal"></div>
          </div>
        </Fragment>
      )}
      <GetOnWaitlistElem uid="benefits" />
    </div>
  );
};

export default withRouter(CompanyBenefits);
